import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { environment } from 'rapid-reimbursement-ui-cov-a/src/environments/environment';
import { LoggingService } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';
import { AnalyticsService } from '../../shared/analytics/analytics.service';

@Component({
  selector: 'app-confirmation',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css'],
})
export class ConfirmationComponent {
  screenId = 'submission';
//
  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private logger: LoggingService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.logger.send({
      reportingFile: 'confirmation.component.ts',
      logLevel: 'INFO',
      message: 'Confirmation page',
      messageId: 'COVA_CONFIRMATION_PAGE',
      logData: {},
    });
    this.analytics.setAnalyticsDataAngular({
      screenChannel: `structural-rapid-reimburse`,
      externalClaimId: sessionStorage.getItem('extClaimId')!,
      externalClientId: sessionStorage.getItem('extClientId')!,
      roleType: sessionStorage.getItem('roleType')!,
      authentication: 'partial',
      screenId: this.screenId,
    });
  }

  goToLanding() {
    this.logger.send({
      reportingFile: 'confirmation.component.ts',
      logLevel: 'INFO',
      message: 'Go to landing',
      messageId: 'COVA_CONFIRMATION_GO_TO_LANDING',
      logData: {},
    });
    this.ngZone.run(() => {
      this.router.navigate([sessionStorage.getItem('baseUrl')], {
        queryParamsHandling: 'merge',
      });
    });
  }

  goToClaimsHub() {
    this.logger.send({
      reportingFile: 'confirmation.component.ts',
      logLevel: 'INFO',
      message: 'Go to Claims Hub',
      messageId: 'COVA_CONFIRMATION_GO_TO_CLAIMS_HUB',
      logData: {},
    });
    window.open(`${environment.claims_hub}${sessionStorage.getItem('baseUrl')}`);
  }
}
