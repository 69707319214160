import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { LoggingService } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';

@Component({
  selector: 'app-long-contract-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './long-contract-modal.component.html',
  styleUrls: ['./long-contract-modal.component.css'],
})
export class LongContractModalComponent implements AfterViewInit, OnDestroy {
  @Input() id: string;
  @Output() onHide = new EventEmitter<void>();
  constructor(public element: ElementRef) {}

  ngAfterViewInit() {
    window.oneX.addElement(this.element.nativeElement);
  }

  ngOnDestroy() {
    window.oneX.removeElement(this.element.nativeElement);
  }

  closeLongContractModal() {
    window.oneX.Modal.hideModal();
    this.onHide.emit();
  }
}
