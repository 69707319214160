import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LoggingService } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';
import { BehaviorSubject, catchError, map, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../environments/environment';
import { ContractMetadata, GetUploadIdResponse, SendContractMetadataResponse } from '../shared/models/api.model';
import { Contract } from '../shared/models/contract.model';
import { CorrelationIdService } from './correlation-id.service';
import { AxiosError } from 'axios';

@Injectable()
export class UploadService {
  private currentPercent = 0;
  private errorCallback: (error: any) => void | undefined;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private correlationId: CorrelationIdService,
    private logger: LoggingService
  ) {}

  start(
    contract: Contract,
    updateCallback: (percentComplete: number) => void,
    doneCallback: (contract: Contract) => void,
    errorCallback: (error: any) => void
  ) {
    this.logger.send({
      reportingFile: 'upload.service.ts',
      logLevel: 'INFO',
      message: 'Start upload service',
      messageId: 'COVA_START_UPLOAD_SERVICE',
      logData: {},
    });
    this.errorCallback = errorCallback;
    this.getUploadId()!.subscribe((uploadId) => {
      this.currentPercent += 10;
      updateCallback(this.currentPercent);
      const imageIdMap = new Map<string, File>();
      const contractMetaData: ContractMetadata = {
        contractId: contract.id,
        contractor: contract.nameOfContractor,
        contractorPhone: contract.contractorsPhoneNumber,
        contractDateSigned: contract.contractSignedDate,
        repairsSameAsEstimate:
          contract.differenceBetweenEstimateAndContract === 'Yes'
            ? 'false'
            : contract.differenceBetweenEstimateAndContract === 'No'
            ? 'true'
            : 'undefined',
        repairsCompleteOrSubstantiallyUnderway:
          contract.repairsSubstantiallyUnderway === 'Yes'
            ? 'true'
            : contract.repairsSubstantiallyUnderway === 'No'
            ? 'false'
            : 'undefined',
        contractTotalAmount: contract.totalAmountOfContract.toString(),
        images: contract.photosOfContract.map((image) => {
          const imageId = uuidv4();
          const imageUpload = {
            imageId: imageId,
            originalFileName: image.name,
            contentType: image.type,
          };
          imageIdMap.set(imageId, image);
          return imageUpload;
        }),
      };

      this.sendContractMetadata(uploadId, contractMetaData).subscribe((imageUrls) => {
        this.currentPercent += 10;
        updateCallback(this.currentPercent);
        const percentIncPerImage = Math.round((1 / imageUrls.length) * 100);
        const ps = new BehaviorSubject(0);

        ps.subscribe((imageNum) => {
          if (imageNum === imageUrls.length) {
            this.currentPercent = 100;
            updateCallback(100);
            setTimeout(() => {
              doneCallback(contract);
            }, 1000);
            return;
          }
          const iurl = imageUrls[imageNum];
          let imageFile = imageIdMap.get(iurl.imageId);
          if (!imageFile) {
            this.logger.send({
              reportingFile: 'upload.service.ts',
              logLevel: 'INFO',
              message: 'Could not find image file with imageId',
              messageId: 'COVA_UPLOAD_CONTRACT_IMAGE_ERROR_NO_IMAGE_ID',
              logData: {
                uploadId: uploadId,
                receiptId: contractMetaData.contractId,
                documentId: iurl.imageId,
              },
            });
            imageFile = contract.photosOfContract[imageNum];
          }

          this.logger.send({
            reportingFile: 'upload.service.ts',
            logLevel: 'INFO',
            message: 'Upload contract image start',
            messageId: 'COVA_UPLOAD_CONTRACT_IMAGE_START',
            logData: {
              uploadId: uploadId,
              receiptId: contractMetaData.contractId,
              documentId: iurl.imageId,
              fileType: imageFile.type,
              fileName: imageFile.name,
              fileSize: imageFile.size.toString(),
            },
          });
          this.sendContractImage(imageFile, iurl.uploadUrl).subscribe(() => {
            this.currentPercent += percentIncPerImage;
            updateCallback(this.currentPercent);
            setTimeout(() => {
              ps.next(imageNum + 1);
            }, 1000);
          });
        });
      });
    });
  }

  getUploadId(checkForUploadId = false, errorCallback?: (err: Error) => void) {
    if (checkForUploadId && !localStorage.getItem('uploadId')) {
      this.logger.send({
        reportingFile: 'upload.service.ts',
        logLevel: 'INFO',
        message: 'uploadId undefined',
        messageId: 'COVA_UPLOAD_SKIP_GET_UPLOAD_ID_UNDEFINED',
        logData: {},
      });
      return;
    }
    const correlationId = this.correlationId.get();
    const url = `${environment.api}`;
    const httpOptions = {
      headers: new HttpHeaders({
        correlationId,
        Authorization: `Bearer ${this.cookieService.get('sf-cauth-lite')}`,
      }),
    };

    const body = {
      externalClaimId: sessionStorage.getItem('extClaimId'),
      externalClientId: sessionStorage.getItem('extClientId'),
      participantRole: sessionStorage.getItem('roleType'),
      coverageType: 'A',
    };

    return this.http.post<GetUploadIdResponse>(url, body, httpOptions).pipe(
      catchError((err: any) => {
        if (err instanceof AxiosError) {
          this.logger.send({
            reportingFile: 'upload.service.ts',
            logLevel: 'ERROR',
            message: 'uploadId error axios',
            messageId: 'COVA_UPLOAD_ID_ERROR',
            logData: {
              calledService: url,
              errorMessage: `${err.message} | response status: ${err.response?.status}`,
              errorCode: `${err.code}`,
            },
          });
        } else if (err instanceof HttpErrorResponse) {
          this.logger.send({
            reportingFile: 'upload.service.ts',
            logLevel: 'ERROR',
            message: 'uploadId error HttpErrorResponse',
            messageId: 'COVA_UPLOAD_ID_ERROR',
            logData: {
              calledService: url,
              errorMessage: `${err.type} | ${err.message}`,
              errorCode: `status: ${err.status} | ${err.statusText}`,
            },
          });
        } else {
          this.logger.send({
            reportingFile: 'upload.service.ts',
            logLevel: 'ERROR',
            message: 'uploadId error other',
            messageId: 'COVA_UPLOAD_ID_ERROR',
            logData: {
              calledService: url,
              errorMessage: `${err.message}`,
              errorCode: `${err.code}`,
            },
          });
        }
        if (errorCallback) {
          errorCallback(err);
        } else {
          this.errorCallback(err);
        }
        localStorage.setItem('uploadId', '');
        throw new Error(`Received error getting uploadId: ${err.status.toString()}`);
      }),
      map((res) => {
        this.logger.send({
          reportingFile: 'upload.service.ts',
          logLevel: 'INFO',
          message: 'uploadId returned',
          messageId: 'COVA_UPLOAD_ID_RETURNED',
          logData: {
            calledService: url,
            uploadId: `${res.payload.uploadId}`,
          },
        });
        localStorage.setItem('uploadId', `${res.payload.uploadId}`);
        return res.payload.uploadId;
      })
    );
  }

  sendContractMetadata(uploadId: string, metadata: ContractMetadata) {
    const correlationId = this.correlationId.get();
    const url = `${environment.api}/${uploadId}/contract`;
    const httpOptions = {
      headers: new HttpHeaders({
        correlationId,
        Authorization: `Bearer ${this.cookieService.get('sf-cauth-lite')}`,
      }),
    };
    const body = JSON.stringify(metadata);
    this.logger.send({
      reportingFile: 'upload.service.ts',
      logLevel: 'INFO',
      message: 'Upload contract metadata start',
      messageId: 'COVA_UPLOAD_CONTRACT_METADATA_START',
      logData: {
        calledService: url,
        uploadId: uploadId,
        receiptId: metadata.contractId,
        retailer: metadata.contractor,
        purchaseDate: metadata.contractDateSigned,
        numberOfPhotos: metadata.images.length.toString(),
      },
    });

    return this.http.post<SendContractMetadataResponse>(url, body, httpOptions).pipe(
      catchError((err) => {
        this.logger.send({
          reportingFile: 'upload.service.ts',
          logLevel: 'ERROR',
          message: 'Contract metadata error',
          messageId: 'COVA_CONTRACT_METADATA_ERROR',
          logData: {
            calledService: url,
          },
        });
        this.errorCallback(err);
        throw new Error(`Received error sending contract metadata: ${err.status.toString()}`);
      }),
      map((response) => {
        this.logger.send({
          reportingFile: 'upload.service.ts',
          logLevel: 'INFO',
          message: 'Contract metadata sent',
          messageId: 'COVA_CONTRACT_METADATA_SENT',
          logData: {
            calledService: url,
          },
        });
        return response.payload.uploadURLs;
      })
    );
  }

  sendContractImage(imageFile: File, s3Url: string) {
    const url = s3Url;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': imageFile.type,
      }),
    };
    return this.http.put(url, imageFile, httpOptions).pipe(
      catchError((err) => {
        this.logger.send({
          reportingFile: 'upload.service.ts',
          logLevel: 'ERROR',
          message: 'Contract image error',
          messageId: 'COVA_CONTRACT_IMAGE_ERROR',
          logData: {
            calledService: url,
          },
        });
        this.sendErrorMessage()!.subscribe(() => {
          this.errorCallback(err);
        });
        throw new Error(`Received error sending contract image: ${err.status.toString()}`);
      }),
      map(() => {
        this.logger.send({
          reportingFile: 'upload.service.ts',
          logLevel: 'INFO',
          message: 'Contract image sent',
          messageId: 'COVA_CONTRACT_IMAGE_SENT',
          logData: {
            calledService: url,
          },
        });
        return true;
      })
    );
  }

  sendCompleteMessage() {
    if (!localStorage.getItem('uploadId')) {
      this.logger.send({
        reportingFile: 'upload.service.ts',
        logLevel: 'INFO',
        message: 'Not sending complete message because no uploadId',
        messageId: 'COVA_UPLOAD_SKIP_COMPLETE_MESSAGE',
        logData: {},
      });
      return;
    }
    this.logger.send({
      reportingFile: 'upload.service.ts',
      logLevel: 'INFO',
      message: 'Upload complete message start',
      messageId: 'COVA_UPLOAD_COMPLETE_MESSAGE_START',
      logData: {},
    });
    const correlationId = this.correlationId.get();
    const url = `${environment.api}/${localStorage.getItem('uploadId')}`;
    const httpOptions = {
      headers: new HttpHeaders({
        correlationId,
        Authorization: `Bearer ${this.cookieService.get('sf-cauth-lite')}`,
      }),
    };
    const body = JSON.stringify({ uploadState: 'UploadComplete' });

    return this.http.patch(url, body, httpOptions).pipe(
      catchError((err) => {
        this.logger.send({
          reportingFile: 'upload.service.ts',
          logLevel: 'ERROR',
          message: 'Complete message error',
          messageId: 'COVA_UPLOAD_COMPLETE_MESSAGE_ERROR',
          logData: {
            calledService: url,
          },
        });
        localStorage.setItem('uploadId', '');
        throw new Error(`Received error sending complete message ${err.status.toString()}`);
      }),
      tap(() => {
        this.logger.send({
          reportingFile: 'upload.service.ts',
          logLevel: 'INFO',
          message: 'Complete message sent',
          messageId: 'COVA_UPLOAD_COMPLETE_MESSAGE_SENT',
          logData: {
            calledService: url,
          },
        });
        localStorage.setItem('uploadId', '');
      })
    );
  }

  sendErrorMessage() {
    if (!localStorage.getItem('uploadId')) {
      this.logger.send({
        reportingFile: 'upload.service.ts',
        logLevel: 'INFO',
        message: 'Not ending error message because no uploadId',
        messageId: 'COVA_UPLOAD_SKIP_ERROR_MESSAGE',
        logData: {},
      });
      return;
    }
    this.logger.send({
      reportingFile: 'upload.service.ts',
      logLevel: 'INFO',
      message: 'Upload error message start',
      messageId: 'COVA_UPLOAD_ERROR_MESSAGE_START',
      logData: {},
    });
    const correlationId = this.correlationId.get();
    const url = `${environment.api}/${localStorage.getItem('uploadId')}`;
    const httpOptions = {
      headers: new HttpHeaders({
        correlationId,
        Authorization: `Bearer ${this.cookieService.get('sf-cauth-lite')}`,
      }),
    };
    const body = JSON.stringify({ uploadState: 'ERROR' });

    return this.http.patch(url, body, httpOptions).pipe(
      catchError((err) => {
        this.logger.send({
          reportingFile: 'upload.service.ts',
          logLevel: 'ERROR',
          message: 'Error message error',
          messageId: 'COVA_UPLOAD_ERROR_MESSAGE_ERROR',
          logData: {
            calledService: url,
          },
        });
        localStorage.setItem('uploadId', '');
        throw new Error(`Received error sending error message: ${err.status.toString()}`);
      }),
      tap(() => {
        this.logger.send({
          reportingFile: 'upload.service.ts',
          logLevel: 'INFO',
          message: 'Error message sent',
          messageId: 'COVA_UPLOAD_ERROR_MESSAGE_SENT',
          logData: {
            calledService: url,
          },
        });
        localStorage.setItem('uploadId', '');
      })
    );
  }
}
