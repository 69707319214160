<div class="-oneX-container" ref="{parentComp}" id="subnav" data-testid="subnav">
  <div class="-oneX-row">
    <div class="-oneX-col">
      <div>
        <div class="subnavContainer">
          <div
            id="subnavLeftBtnContainer"
            data-testid="subnavLeftBtnContainer"
            class="subnavLeftBtnContainer"
            tabIndex="0"
            aria-label="leftBtnText"
            (click)="leftButtonWasClicked($event)"
            (keydown.enter)="leftButtonWasClicked($event)"
          >
            <div class="subnavLeftBtnIconContainer" style="display: block">
              <div class="-oneX-icon-container -oneX-icon--interactive">
                <div
                  class="{{ '-oneX-icon' + leftButtonClass }}"
                  data-icon="trash_24"
                  id="subnavLeftBtnIcon"
                  data-testid="subnavLeftBtnIcon"
                ></div>
              </div>
            </div>
            <div class="subnavLeftBtnTextContainer" style="display: block">
              <div class="-oneX-icon-container -oneX-icon--interactive">
                <div
                  class="-oneX-link--block--variant -oneX-link-secondary undefined"
                  id="subnavLeftBtnText"
                  data-testid="subnavLeftBtnText"
                  aria-hidden
                >
                  {{ leftButtonText }}
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="isRightButton"
            id="subnavRightBtnContainer"
            data-testid="subnavRightBtnContainer"
            class="subnavRightBtnContainer"
            aria-label="rightBtnText"
            style="display: grid"
            tabIndex="0"
            (click)="rightButtonWasClicked()"
            (keydown.enter)="rightButtonWasClicked()"
          >
            <div class="subnavLeftBtnTextContainer" style="display: block">
              <div class="-oneX-icon-container -oneX-icon--interactive">
                <div
                  class="-oneX-link--block--variant -oneX-link-secondary undefined"
                  id="subnavRightBtnText"
                  data-testid="subnavRightBtnText"
                  aria-hidden
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
