import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-doc-type-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './doc-type-modal.component.html',
  styleUrls: ['./doc-type-modal.component.css'],
})
export class DocTypeModalComponent implements AfterViewInit, OnDestroy {
  @Input() id: string;

  constructor(private element: ElementRef) {}

  ngAfterViewInit() {
    window.oneX.addElement(this.element.nativeElement);
  }

  ngOnDestroy() {
    window.oneX.removeElement(this.element.nativeElement);
  }

  closeDocTypeModal() {
    window.oneX.Modal.hideModal();
  }
}
