<div id="homePage" data-testid="homePage" class="homePage">
  <div class="-oneX-container">
    <div class="-oneX-row">
      <div
        class="-oneX-col-xs-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-12 -oneX-offset-md-0 -oneX-col-lg-11 -oneX-offset-lg-1 -oneX-col-xl-11 -oneX-offset-xl-1"
      >
        <h1 id="homeAppTitle" data-testid="homeAppTitle" class="homeAppTitle">
          Rapid Reimbursement for Dwelling or Structural Damage
        </h1>
      </div>
    </div>
    <div class="-oneX-row">
      <div
        class="-oneX-col-xs-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-12 -oneX-offset-md-0 -oneX-col-lg-3 -oneX-offset-lg-1 -oneX-col-xl-3 -oneX-offset-xl-1"
      >
        <h4 id="homeInstructionsTitle" data-testid="homeInstructionsTitle" class="homeInstructionsTitle">
          How it works
        </h4>
      </div>
      <div
        class="-oneX-col-xs-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-6 -oneX-offset-md-0 -oneX-col-lg-4 -oneX-offset-lg-0 -oneX-col-xl-4 -oneX-offset-xl-0 instruction-list"
      >
        <ol>
          <li id="homeInstruction0" data-testid="homeInstruction0" class="-oneX-body--primary">
            <span></span>Have your State Farm damage estimate nearby.
          </li>
          <li id="homeInstruction1" data-testid="homeInstruction1" class="-oneX-body--primary">
            <span></span>Gather your contractor information and receipts/invoices.
          </li>
          <li id="homeInstruction2" data-testid="homeInstruction2" class="-oneX-body--primary">
            <span></span>Review the scope of repairs to make sure it matches the State Farm estimate.
          </li>
        </ol>
      </div>
      <div
        class="-oneX-col-xs-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-6 -oneX-offset-md-0 -oneX-col-lg-4 -oneX-offset-lg-0 -oneX-col-xl-4 -oneX-offset-xl-0 instruction-list"
      >
        <ol start="4">
          <li id="homeInstruction3" data-testid="homeInstruction3" class="-oneX-body--primary">
            <span></span>Upload your receipts or invoices.
          </li>
          <li id="homeInstruction4" data-testid="homeInstruction4" class="-oneX-body--primary">
            <span></span>We'll review your request and determine amounts that may be payable.
          </li>
        </ol>
      </div>
    </div>
    <div class="-oneX-row">
      <div
        class="-oneX-col-xs-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-12 -oneX-offset-md-0 -oneX-col-lg-11 -oneX-offset-lg-1 -oneX-col-xl-11 -oneX-offset-xl-1"
      >
        <div class="startButtonContainer">
          <button
            id="homeGetStartedBtn"
            data-testid="homeGetStartedBtn"
            type="submit"
            class="-oneX-btn-primary startButton"
            (click)="goToEnterContract()"
          >
            Get started
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Session Timeout Modal -->
  <div
    id="sessionTimeoutModal"
    data-testid="sessionTimeoutModal"
    data-session-timeout="true"
    class="-oneX-modal__container"
    data-disabled-scrim="true"
    style="display: none"
  >
    <div class="-oneX-modal-content" aria-labelledby="dialogAction" role="dialog">
      <div
        id="sessionTimeoutModalHeading"
        data-testid="sessionTimeoutModalHeading"
        class="-oneX-modal-heading"
        aria-level="2"
        role="heading"
        tabindex="-1"
      >
        Your session expired due to inactivity
      </div>
      <div class="-oneX-modal-body" style="padding-bottom: 2rem">
        <div id="sessionTimeoutModalBodyText" data-testid="sessionTimeoutModalBodyText" class="-oneX-body--primary">
          <div id="sessionTimeoutModalBodyText" data-testid="sessionTimeoutModalBodyText">
            Any unsubmitted contracts were deleted.
          </div>
        </div>
      </div>
      <div class="-oneX-modal-footer">
        <div class="-oneX-row">
          <div class="-oneX-modal-primary__div -oneX-col">
            <button
              (click)="hideSessionTimeoutModal()"
              id="sessionTimeoutModalExpireBtn"
              data-testid="sessionTimeoutModalExpireBtn"
              class="-oneX-btn-primary"
              ref="sessionTimeoutModalExpireBtnRef"
            >
              Enter contracts
            </button>
          </div>
          <div class="-oneX-modal-secondary__div -oneX-col">
            <div id="sessionTimeoutModalCallOptionText" data-testid="sessionTimeoutModalCallOptionText">
              <span id="sessionTimeoutModalCallOptionTextFirst" data-testid="sessionTimeoutModalCallOptionTextFirst">
                Or call us at
              </span>
              &nbsp;
              <a
                class="-oneX-link--block"
                id="sessionTimeoutModalPhoneLink"
                data-testid="sessionTimeoutModalPhoneLink"
                style="display: inline-block"
                href="tel:800-732-5246"
              >
                800-732-5246
              </a>
              .
            </div>
          </div>
        </div>
      </div>
      <button
        (click)="hideSessionTimeoutModal()"
        type="button"
        ref="sessionTimeoutModalCloseBtnRef"
        class="-oneX-close-X-button"
        id="sessionTimeoutModalCloseBtn"
        data-testid="sessionTimeoutModalCloseBtn"
        aria-label="Close Session Time Out Modal"
      >
        <span class="-oneX-icon--close"></span>
      </button>
    </div>
  </div>
  <!-- /Session Timeout Modal -->
</div>
