import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements AfterViewInit, OnDestroy {
  constructor(private element: ElementRef) {}

  ngAfterViewInit() {
    //globalThis.oneX.addElement(this.element.nativeElement);
  }

  ngOnDestroy() {
    //globalThis.oneX.removeElement(this.element.nativeElement);
  }
}
