import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import axios from 'axios';
import { environment } from '../environments/environment';
import { CorrelationIdService } from './correlation-id.service';

export enum CliffLogLevel {
  INFO = 'info',
  ERROR = 'error',
}

export interface CliffLog {
  [messageId: string]: {
    message: string;
    reportingFile: string;
    logLevel: CliffLogLevel;
  };
}

export interface LogData {
  extClaimId?: string;
  externalClientId?: string;
  roleType?: string;
  uploadId?: string;
  receiptId?: string;
  retailer?: string;
  purchaseDate?: string;
  numberOfPhotos?: string;
  documentId?: string;
  fileType?: string;
  fileName?: string;
  fileSize?: string;
  httpResponse?: string;
  errorCode?: string;
  errorMessage?: string;
  calledService?: string;
}
export interface Log {
  logLevel: 'INFO' | 'ERROR' | 'WARN';
  messageId: string;
  message: string;
  reportingFile: string;
  logData: LogData;
  entryPoint?: string;
}

@Injectable()
export class LoggingService {
  constructor(private http: HttpClient, private correlationId: CorrelationIdService) {}

  send(log: Log) {
    const correlationId = this.correlationId.get();
    const httpOptions = {
      headers: new HttpHeaders({
        correlationId,
      }),
    };

    log.logData.extClaimId = sessionStorage.getItem('extClaimId') ?? undefined;
    log.logData.externalClientId = sessionStorage.getItem('extClientId') ?? undefined;
    log.logData.roleType = sessionStorage.getItem('roleType') ?? undefined;
    log.entryPoint = 'COV_A';
    const body = JSON.stringify(log);
    const { url, api } = environment.cliff;

    return axios({
      method: 'post',
      url: url,
      headers: {
        'x-api-key': api,
        applicationId: 'rapid-reimbursement',
        correlationId: correlationId,
        'Content-Type': 'application/json',
      },
      responseType: 'text',
      data: body,
    });
  }
}
