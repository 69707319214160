<div
  [id]="id"
  data-testid="warningTimeoutModal"
  data-session-timeout="true"
  class="-oneX-modal__container"
  data-disabled-scrim="true"
  style="display: none"
>
  <div class="-oneX-modal-content" aria-labelledby="dialogAction" role="dialog">
    <div
      id="warningTimeoutModalHeading"
      data-testid="warningTimeoutModalHeading"
      class="-oneX-modal-heading"
      aria-level="2"
      role="heading"
      tabindex="-1"
    >
      Are you still there?
    </div>

    <div class="-oneX-modal-body" style="padding-bottom: 2rem">
      <div id="warningTimeoutModalBodyText" data-testid="warningTimeoutModalBodyText" class="-oneX-body--primary">
        <div style="padding-bottom: 1rem">
          <span id="warningTimeoutModalBodyTextFirst" data-testid="warningTimeoutModalBodyTextFirst">
            Your session will expire in
          </span>
          <span
            id="warningTimeoutModalBodyTextTimeRemaining"
            data-testid="warningTimeoutModalBodyTextTimeRemaining"
            role="timer"
            aria-atomic="true"
          >
            {{ countdown }}
          </span>
          <span id="warningTimeoutModalBodyTextSecond" data-testid="warningTimeoutModalBodyTextSecond">
            seconds. Any unsubmitted contracts will be deleted.
          </span>
        </div>
        <div id="warningTimeoutModalBodyTextThird" data-testid="warningTimeoutModalBodyTextThird">
          Would you like to extend your session?
        </div>
      </div>
    </div>
    <div class="-oneX-modal-footer">
      <div class="-oneX-row">
        <div class="-oneX-modal-primary__div -oneX-col">
          <button
            (click)="extendSession()"
            onKeyDown="extendSession()"
            class="-oneX-btn-primary"
            id="warningTimeoutModalExtendBtn"
            data-testid="warningTimeoutModalExtendBtn"
            ref="warningTimeoutModalExtendBtnRef"
          >
            Extend
          </button>
        </div>
        <div class="-oneX-modal-secondary__div -oneX-col">
          <button
            (click)="expireSession()"
            onKeyDown="expireSession()"
            id="warningTimeoutModalExpireBtn"
            data-testid="warningTimeoutModalExpireBtn"
            class="-oneX-btn-secondary"
            ref="warningTimeoutModalExpireBtnRef"
          >
            Expire
          </button>
        </div>
      </div>
    </div>
    <button
      type="button"
      (click)="extendSession()"
      onKeyDown="extendSession()"
      ref="warningTimeoutModalCloseBtnRef"
      class="-oneX-close-X-button"
      id="warningTimeoutModalCloseBtn"
      data-testid="warningTimeoutModalCloseBtn"
      aria-label="Close Session Time Out Modal"
    >
      <span class="-oneX-icon--close"></span>
    </button>
  </div>
</div>
