import { createSelector } from '@ngrx/store';
import { AppState } from '../app/app.state';
import { ContractsState } from './contracts.state';

export const selectContractsState = (state: AppState) => state.contracts;
export const selectContracts = createSelector(selectContractsState, (state: ContractsState) => state.contracts);

export const selectContractById = (id: string) =>
  createSelector(selectContractsState, (state: ContractsState) => {
    if (state.contracts) {
      return state.contracts.find((contract) => {
        return contract.id === id;
      });
    }
    return undefined;
  });

export const selectUnsubmittedContract = createSelector(selectContractsState, (state: ContractsState) => {
  if (state.contracts) {
    return state.contracts.find((contract) => {
      return contract.submitted === false;
    });
  }
  return undefined;
});

export const selectUnsubmittedContracts = createSelector(selectContractsState, (state: ContractsState) => {
  return state.contracts.filter((contract) => {
    return contract.submitted === false;
  });
});
