import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-contract-upload',
  standalone: true,
  templateUrl: './contract-upload.component.html',
  styleUrls: ['./contract-upload.component.css'],
  imports: [RouterLink],
})
export class ContractUploadComponent implements AfterViewInit, OnDestroy {
  authUrl: string;
  contractId: string;
  constructor(private el: ElementRef, private route: ActivatedRoute, private router: Router, private ngZone: NgZone) {}

  ngOnInit() {
    const tempId = '123';
    this.contractId = this.route.snapshot.params['contractId'];
    this.contractId = tempId;
    const extClaimId = this.route.snapshot.params['extClaimId'];
    const extClientId = this.route.snapshot.params['extClientId'];
    const roleType = this.route.snapshot.params['roleType'];
    this.authUrl = `claim/${extClaimId}/client/${extClientId}/role/${roleType}`;
  }
  ngAfterViewInit() {
    window.oneX.addElement(this.el.nativeElement);
  }

  ngOnDestroy() {
    window.oneX.removeElement(this.el.nativeElement);
  }

  getName() {
    let firstName = 'Thomas';
    let lastName = 'Williams';
    let name = (firstName + ' ' + lastName).toUpperCase();
    return name;
  }

  getContractStatus() {
    return 'Not submitted';
  }

  getMessage() {
    return "This contract/receipt can't be changed once it is submitted";
  }

  navigateToEditPage() {
    this.ngZone.run(() => {
      this.router.navigate([`${this.authUrl}/contract/${this.contractId}/edit`], { queryParamsHandling: 'merge' });
    });
  }
}
