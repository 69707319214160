import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'rapid-reimbursement-ui-cov-a/src/app/app.state';
import { DocTypeModalComponent } from 'rapid-reimbursement-ui-cov-a/src/modals/doc-type-modal/doc-type-modal.component';
import { LongContractModalComponent } from 'rapid-reimbursement-ui-cov-a/src/modals/long-contract-modal/long-contract-modal.component';
import { addContract, updateContract } from 'rapid-reimbursement-ui-cov-a/src/ngrx/contracts.action';
import { selectContractById, selectContracts } from 'rapid-reimbursement-ui-cov-a/src/ngrx/contracts.selector';
import { LoggingService } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';
import { CONTRACT_EMPTY, Contract } from 'rapid-reimbursement-ui-cov-a/src/shared/models/contract.model';
import { SubnavComponent } from 'rapid-reimbursement-ui-cov-a/src/shared/subnav/subnav.component';
import { Observable, ReplaySubject, take, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DeleteContractModalComponent } from '../../modals/delete-contract-modal/delete-contract-modal.component';
import { AnalyticsService } from '../../shared/analytics/analytics.service';
import { DragAndDropDirective } from '../../shared/directives/drag-and-drop.directive';

@Component({
  selector: 'app-contract',
  standalone: true,
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.css'],
  imports: [
    SubnavComponent,
    CommonModule,
    DocTypeModalComponent,
    DragAndDropDirective,
    DeleteContractModalComponent,
    LongContractModalComponent,
  ],
})
export class ContractComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  contractorSignDate: string | undefined;
  currentDate = new Date();
  currentDateText = this.formatDate(this.currentDate);
  hasLongContractModalBeenOpened: boolean;
  readonly MAX_CONTRACT_PHOTOS = 3;
  contractCount: number | undefined;
  contractList: any[] = [];
  fileList: File[] = [];
  maxFileSize = 1e7;
  hasFileSizeError = false;
  hasFileTypeError = false;
  isUpdated = true;
  isSubmitted = false;
  contracts$: Observable<Contract[]>;
  editContract$: Observable<Contract | undefined>;
  @ViewChild('fileDropRef', { static: false }) fileDropEl!: ElementRef;
  @ViewChild('repairsUnderway', { static: false }) selectRepair: ElementRef;
  @ViewChild('estimateDiscrepancy', { static: false }) selectAnyDifference: ElementRef;
  contractId: string | undefined;
  longContractModalId = `longContractModal_${uuidv4()}`;
  docTypesModalId = `docTypesModal_${uuidv4()}`;
  deleteContractModalId = `deleteContractModal_${uuidv4()}`;
  screenId: string;

  constructor(
    private el: ElementRef,
    private route: ActivatedRoute,
    public router: Router,
    private store: Store<AppState>,
    private ngZone: NgZone,
    private logger: LoggingService,
    private analytics: AnalyticsService
  ) {
    this.hasLongContractModalBeenOpened = false;
  }

  ngOnInit() {
    this.logger.send({
      reportingFile: 'contract.component.ts',
      logLevel: 'INFO',
      message: 'Contract page',
      messageId: 'COVA_CONTRACT_PAGE',
      logData: {},
    });
    this.contracts$ = this.store.select(selectContracts);
    this.contracts$.subscribe((contract) => {
      this.hasLongContractModalBeenOpened = contract.length > 0;
      this.contractCount = contract.length;
    });
    this.contractId = this.route.snapshot.paramMap.get('contractId') ?? undefined;
    this.isUpdated = !!this.contractId;
    this.screenId = `contractor-details-${this.contractCount}`;

    this.analytics.setAnalyticsDataAngular({
      screenChannel: `structural-rapid-reimburse`,
      externalClaimId: sessionStorage.getItem('extClaimId')!,
      externalClientId: sessionStorage.getItem('extClientId')!,
      roleType: sessionStorage.getItem('roleType')!,
      authentication: 'partial',
      screenId: this.screenId,
    });

    if (this.contractId) {
      this.logger.send({
        reportingFile: 'contract.component.ts',
        logLevel: 'INFO',
        message: 'Valid  contract ID',
        messageId: 'COVA_VALID_CONTRACT_ID',
        logData: {},
      });
      this.editContract$ = this.store.select(selectContractById(this.contractId));
      this.editContract$.pipe(takeUntil(this.destroyed$)).subscribe((contract) => {
        if (!contract) {
          this.logger.send({
            reportingFile: 'contract.component.ts',
            logLevel: 'ERROR',
            message: 'Malformed request',
            messageId: 'COVA_CONTRACT_PAGE_MALFORMED_REQUEST',
            logData: {},
          });
          this.ngZone.run(() => {
            this.router.navigate(['error-malformed-request'], { queryParamsHandling: 'merge' });
          });
          return;
        }
        const oldContract = contract;
        (<HTMLInputElement>document.getElementById('contractorName')).value = oldContract.nameOfContractor;
        (<HTMLInputElement>document.getElementById('contractorPhoneNumber')).value = oldContract.contractorsPhoneNumber;
        (<HTMLInputElement>document.getElementById('contractorSignDate')).value = oldContract.contractSignedDate;
        (<HTMLInputElement>document.getElementById('contractAmount')).value =
          oldContract.totalAmountOfContract.toString();
        (<HTMLInputElement>document.getElementById('repairsUnderway')).value = oldContract.repairsSubstantiallyUnderway;
        (<HTMLInputElement>document.getElementById('estimateDiscrepancy')).value =
          oldContract.differenceBetweenEstimateAndContract;
        this.addFiles(oldContract.photosOfContract);
        this.isSubmitted = oldContract.submitted;
        if (this.isSubmitted) {
          this.logger.send({
            reportingFile: 'contract.component.ts',
            logLevel: 'INFO',
            message: 'Contract submitted',
            messageId: 'COVA_CONTRACT_SUBMITTED',
            logData: {},
          });
          this.screenId = `view-contract-scope=${this.contractCount}`;
          (<HTMLInputElement>document.getElementById('contractorName')).readOnly = true;
          (<HTMLInputElement>document.getElementById('contractorPhoneNumber')).readOnly = true;
          (<HTMLInputElement>document.getElementById('contractorSignDate')).readOnly = true;
          (<HTMLInputElement>document.getElementById('contractAmount')).readOnly = true;
          (<HTMLInputElement>document.getElementById('repairsUnderway')).readOnly = true;
          (<HTMLInputElement>document.getElementById('repairsUnderway')).disabled = true;
          (<HTMLInputElement>document.getElementById('estimateDiscrepancy')).readOnly = true;
          (<HTMLInputElement>document.getElementById('estimateDiscrepancy')).disabled = true;
          (<HTMLButtonElement>document.getElementById('contractorSignDateBtn')).disabled = true;
          this.analytics.setAnalyticsDataAngular({
            screenChannel: `structural-rapid-reimburse`,
            externalClaimId: sessionStorage.getItem('extClaimId')!,
            externalClientId: sessionStorage.getItem('extClientId')!,
            roleType: sessionStorage.getItem('roleType')!,
            authentication: 'partial',
            screenId: this.screenId,
          });
        } else {
          this.screenId = `edit-contract-scope-${this.contractCount}`;
          this.analytics.setAnalyticsDataAngular({
            screenChannel: `structural-rapid-reimburse`,
            externalClaimId: sessionStorage.getItem('extClaimId')!,
            externalClientId: sessionStorage.getItem('extClientId')!,
            roleType: sessionStorage.getItem('roleType')!,
            authentication: 'partial',
            screenId: this.screenId,
          });
        }
      });
    }
  }

  ngAfterViewInit() {
    window.oneX.addElement(this.el.nativeElement);

    const customCalendar = window.oneX.Datepicker.calendars['contractorSignDate'];
    customCalendar.setMax(this.currentDate, true);
  }

  ngOnDestroy() {
    window.oneX.removeElement(this.el.nativeElement);
  }

  updateDate(contractorSignDate: any) {
    const valid = Date.parse(contractorSignDate.target.value) <= Date.parse(this.formatDate(this.currentDate));

    if (valid) {
      this.logger.send({
        reportingFile: 'contract.component.ts',
        logLevel: 'INFO',
        message: 'Valid contractor sign date',
        messageId: 'COVA_VALID_CONTRACTOR_SIGN_DATE',
        logData: {},
      });
      this.contractorSignDate = contractorSignDate.target.value;
      document.getElementById('errorMessage')!.setAttribute('style', 'display:none;');
    } else {
      this.logger.send({
        reportingFile: 'contract.component.ts',
        logLevel: 'INFO',
        message: 'Invalid contractor sign date',
        messageId: 'COVA_INVALID_CONTRACTOR_SIGN_DATE',
        logData: {},
      });
      this.contractorSignDate = '';
      contractorSignDate.target.value = '';
      document.getElementById('errorMessage')!.setAttribute('style', '');
    }
  }

  formatDate(date: Date) {
    const formatMonth = date.getMonth() + 1;
    const formatDay = date.getDate();
    const formatYear = date.getFullYear();
    const dateText = formatMonth + '-' + formatDay + '-' + formatYear;
    return dateText;
  }

  leftButtonClicked(event: any) {
    if (this.isUpdated) {
      this.analytics.setAnalyticsDataAngular({
        screenChannel: `structural-rapid-reimburse`,
        externalClaimId: sessionStorage.getItem('extClaimId')!,
        externalClientId: sessionStorage.getItem('extClientId')!,
        roleType: sessionStorage.getItem('roleType')!,
        authentication: 'partial',
        screenId: 'confirm-edit-delete-modal',
      });
      if (this.isSubmitted) {
        this.logger.send({
          reportingFile: 'contract.component.ts',
          logLevel: 'INFO',
          message: 'Go to review',
          messageId: 'COVA_CONTRACT_GO_TO_REVIEW',
          logData: {},
        });
        this.ngZone.run(() => {
          this.router.navigate([`${sessionStorage.getItem('baseUrl')}/review`], { queryParamsHandling: 'merge' });
        });
      } else {
        this.logger.send({
          reportingFile: 'contract.component.ts',
          logLevel: 'INFO',
          message: 'Delete modal opened',
          messageId: 'COVA_CONTRACT_LEFT_BUTTON_DELETE_MODAL_OPENED',
          logData: {},
        });
        const $returnFocus = window.oneX.$(event.target);
        window.oneX.Modal.showModal($returnFocus, this.deleteContractModalId);
      }
    } else {
      this.logger.send({
        reportingFile: 'contract.component.ts',
        logLevel: 'INFO',
        message: 'Go to landing',
        messageId: 'COVA_CONTRACT_LEFT_BUTTON_GO_TO_LANDING',
        logData: {},
      });
      this.ngZone.run(() => {
        this.router.navigate([sessionStorage.getItem('baseUrl')], { queryParamsHandling: 'merge' });
      });
    }
  }

  rightButtonClicked() {
    this.contracts$.pipe(take(1)).subscribe((rs) => {
      if (rs.length > 0) {
        this.logger.send({
          reportingFile: 'contract.component.ts',
          logLevel: 'INFO',
          message: 'Go to review',
          messageId: 'COVA_CONTRACT_RIGHT_BUTTON_GO_TO_REVIEW',
          logData: {},
        });
        this.ngZone.run(() => {
          this.router.navigate([`${sessionStorage.getItem('baseUrl')}/review`], { queryParamsHandling: 'merge' });
        });
      } else {
        this.logger.send({
          reportingFile: 'contract.component.ts',
          logLevel: 'INFO',
          message: 'Go to landing',
          messageId: 'COVA_CONTRACT_RIGHT_BUTTON_GO_TO_LANDING',
          logData: {},
        });
        this.ngZone.run(() => {
          this.router.navigate([sessionStorage.getItem('baseUrl')], { queryParamsHandling: 'merge' });
        });
      }
    });
  }

  openCommonDocModal(event: any) {
    this.logger.send({
      reportingFile: 'contract.component.ts',
      logLevel: 'INFO',
      message: 'Common doc modal opened',
      messageId: 'COVA_COMMON_DOC_MODAL_OPENED',
      logData: {},
    });
    this.screenId = 'common-doc-type-modal';
    const $returnFocus = window.oneX.$(event.target);
    window.oneX.Modal.showModal($returnFocus, this.docTypesModalId);
    this.analytics.setAnalyticsDataAngular({
      screenChannel: `structural-rapid-reimburse`,
      externalClaimId: sessionStorage.getItem('extClaimId')!,
      externalClientId: sessionStorage.getItem('extClientId')!,
      roleType: sessionStorage.getItem('roleType')!,
      authentication: 'partial',
      screenId: this.screenId,
    });
  }

  openFilePicker() {
    this.logger.send({
      reportingFile: 'contract.component.ts',
      logLevel: 'INFO',
      message: 'File picker opened',
      messageId: 'COVA_FILE_PICKER_OPENED',
      logData: {},
    });
    const filePicker = document.getElementById('contractorUploadPhotosInput_control');
    filePicker!.click();
  }

  fileUploadClick(event: any) {
    if (event.target.id === 'contractorUploadPhotosInput_control') {
      return;
    }
    if (!this.hasLongContractModalBeenOpened) {
      this.logger.send({
        reportingFile: 'contract.component.ts',
        logLevel: 'INFO',
        message: 'Long contract modal opened',
        messageId: 'COVA_LONG_CONTRACT_MODAL_OPENED',
        logData: {},
      });
      this.analytics.setAnalyticsDataAngular({
        screenChannel: `structural-rapid-reimburse`,
        externalClaimId: sessionStorage.getItem('extClaimId')!,
        externalClientId: sessionStorage.getItem('extClientId')!,
        roleType: sessionStorage.getItem('roleType')!,
        authentication: 'partial',
        screenId: 'long-invoice-modal',
      });
      const $returnFocus = window.oneX.$(event.target);
      window.oneX.Modal.showModal($returnFocus, this.longContractModalId);
      this.hasLongContractModalBeenOpened = true;
    } else {
      this.openFilePicker();
    }
  }

  addFiles(files: FileList | File[]) {
    let contractLengthAfterDrop = this.contractList.length + 1;
    const fileList = Object.values(files);
    this.hasFileTypeError = false;
    this.hasFileSizeError = false;

    fileList.forEach((file) => {
      if (file.size > this.maxFileSize) {
        this.logger.send({
          reportingFile: 'contract.component.ts',
          logLevel: 'ERROR',
          message: 'File size over max',
          messageId: 'COVA_FILE_SIZE_OVER_MAX_ERROR',
          logData: {},
        });
        this.hasFileSizeError = true;
      } else {
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
          this.logger.send({
            reportingFile: 'contract.component.ts',
            logLevel: 'ERROR',
            message: 'Invalid file type',
            messageId: 'COVA_INVALID_FILE_TYPE_ERROR',
            logData: {},
          });
          this.hasFileTypeError = true;
        } else {
          if (contractLengthAfterDrop <= this.MAX_CONTRACT_PHOTOS) {
            this.contractList.push(URL.createObjectURL(file)); // used for rendering with ngFor
            this.fileList = [...this.fileList, file]; // this will be sent to the ngrx store
          }
          contractLengthAfterDrop++;
        }
      }
    });
  }

  deleteFile(index: number, e: Event) {
    this.logger.send({
      reportingFile: 'contract.component.ts',
      logLevel: 'INFO',
      message: 'File deleted',
      messageId: 'COVA_FILE_DELETED',
      logData: {},
    });
    e.stopPropagation();
    this.contractList.splice(index, 1);
    this.fileList.splice(index, 1);
  }

  isNextDisabled(): boolean {
    return !(
      this.fileList.length !== 0 &&
      !!(<HTMLInputElement>document.getElementById('contractorName')).value &&
      !!(<HTMLInputElement>document.getElementById('contractorSignDate')).value &&
      !!(<HTMLInputElement>document.getElementById('contractAmount')).value &&
      !!(<HTMLInputElement>document.getElementById('repairsUnderway')).value &&
      !!(<HTMLInputElement>document.getElementById('estimateDiscrepancy')).value
    );
  }

  onNextButtonClicked() {
    this.logger.send({
      reportingFile: 'contract.component.ts',
      logLevel: 'INFO',
      message: 'Next button clicked',
      messageId: 'COVA_CONTRACT_NEXT_BUTTON_CLICKED',
      logData: {},
    });
    this.addContractToState();
    this.ngZone.run(() => {
      this.router.navigate([`${sessionStorage.getItem('baseUrl')}/review`], { queryParamsHandling: 'merge' });
    });
  }

  addContractToState() {
    let newContract = CONTRACT_EMPTY();
    newContract.nameOfContractor = (<HTMLInputElement>document.getElementById('contractorName')).value;
    newContract.contractorsPhoneNumber = (<HTMLInputElement>document.getElementById('contractorPhoneNumber')).value;
    newContract.contractSignedDate = (<HTMLInputElement>document.getElementById('contractorSignDate')).value;
    newContract.totalAmountOfContract = parseFloat(
      (<HTMLInputElement>document.getElementById('contractAmount')).value.replace(/[$,]/g, '')
    );
    newContract.repairsSubstantiallyUnderway = (<HTMLInputElement>document.getElementById('repairsUnderway')).value;
    newContract.differenceBetweenEstimateAndContract = (<HTMLInputElement>(
      document.getElementById('estimateDiscrepancy')
    )).value;
    newContract.photosOfContract = this.fileList;

    if (this.isUpdated) {
      newContract.id = this.contractId!;
      this.logger.send({
        reportingFile: 'contract.component.ts',
        logLevel: 'INFO',
        message: 'Contract updated',
        messageId: 'COVA_CONTRACT_UPDATED',
        logData: {},
      });
      this.store.dispatch(updateContract({ contract: newContract }));
    } else {
      this.logger.send({
        reportingFile: 'contract.component.ts',
        logLevel: 'INFO',
        message: 'Contract added',
        messageId: 'COVA_CONTRACT_ADDED',
        logData: {},
      });
      this.store.dispatch(addContract({ contract: newContract }));
      this.analytics.setAnalyticsDataAngular({
        screenChannel: `rapid-reimbursement`,
        externalClaimId: sessionStorage.getItem('extClaimId')!,
        externalClientId: sessionStorage.getItem('extClientId')!,
        roleType: sessionStorage.getItem('roleType')!,
        authentication: 'partial',
        repairsUnderway: newContract.repairsSubstantiallyUnderway,
        estimateDiscrepancy: newContract.differenceBetweenEstimateAndContract,
        screenId: this.screenId,
      });
    }
  }
}
