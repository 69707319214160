<div [id]="id" class="-oneX-modal__container" style="display: none" data-testid="docTypesModal">
  <div class="-oneX-modal-content" aria-labelledby="docTypeHeading" role="dialog">
    <div class="-oneX-modal-body">
      <h2 id="docTypeHeading" class="-oneX-modal-heading" tabindex="-1">Common documentation types</h2>
      <div class="-oneX-row">
        <div class="-oneX-col">
          <p>Common documentation types include:</p>
        </div>
      </div>
      <div class="-oneX-row">
        <div class="-oneX-col">
          <ul>
            <li>Documentation confirming the repairs are substantially underway</li>
            <ul>
              <li>Photo(s) showing work substantially underway or completed</li>
              <li>Receipts for the purchase of materials</li>
            </ul>
            <li>Signed contract for work that is acceptable to us</li>
            <li>A final invoice or certificate of completion from your contractor</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="-oneX-modal-footer">
      <div class="-oneX-row">
        <div class="-oneX-modal-primary__div -oneX-col">
          <button (click)="closeDocTypeModal()" class="-oneX-btn-primary" data-testid="docTypesModalOKBtn">
            OK, got it
          </button>
        </div>
      </div>
    </div>
    <button type="button" class="-oneX-close-X-button" aria-label="Close Modal" data-testid="docTypesModalXCloseBtn">
      <span class="-oneX-icon--close"> </span>
    </button>
  </div>
</div>
