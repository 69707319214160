import { Component } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { SessionTimeoutModalComponent } from 'rapid-reimbursement-ui-cov-a/src/modals/session-timeout-modal/session-timeout-modal.component';
import { FooterComponent } from 'rapid-reimbursement-ui-cov-a/src/shared/footer/footer.component';
import { HeaderComponent } from 'rapid-reimbursement-ui-cov-a/src/shared/header/header.component';
import { SubnavComponent } from 'rapid-reimbursement-ui-cov-a/src/shared/subnav/subnav.component';
import { Observable } from 'rxjs';
import { selectContracts } from '../ngrx/contracts.selector';
import { Contract } from '../shared/models/contract.model';
import { AppState } from './app.state';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    RouterOutlet,
    HeaderComponent,
    SubnavComponent,
    FooterComponent,
    SessionTimeoutModalComponent,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  title = 'rapid-reimbursement';
  contracts$: Observable<Contract[]>;

  constructor(private store: Store<AppState>) {
    this.contracts$ = this.store.select(selectContracts);
  }
}
