import { CommonModule } from '@angular/common';
import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'rapid-reimbursement-ui-cov-a/src/app/app.state';
import { updateContract } from 'rapid-reimbursement-ui-cov-a/src/ngrx/contracts.action';
import { selectUnsubmittedContracts } from 'rapid-reimbursement-ui-cov-a/src/ngrx/contracts.selector';
import { LoggingService } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';
import { UploadService } from 'rapid-reimbursement-ui-cov-a/src/services/upload.service';
import { CONTRACT_COPY, Contract } from 'rapid-reimbursement-ui-cov-a/src/shared/models/contract.model';
import { BehaviorSubject, ReplaySubject, take, takeUntil, tap } from 'rxjs';
import { CorrelationIdService } from '../../services/correlation-id.service';
import { AnalyticsService } from '../../shared/analytics/analytics.service';

@Component({
  selector: 'app-upload',
  standalone: true,
  imports: [CommonModule, RouterModule],
  providers: [UploadService, CorrelationIdService],
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
})
export class UploadComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  contractsToUpload: Contract[];
  addAnotherAfterUpload: boolean;
  currentPercent = new BehaviorSubject(0);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    public upload: UploadService,
    private ngZone: NgZone,
    private logger: LoggingService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.logger.send({
      reportingFile: 'upload.component.ts',
      logLevel: 'INFO',
      message: 'Upload page',
      messageId: 'CVOA_UPLOAD_PAGE',
      logData: {},
    });
    this.analytics.setAnalyticsDataAngular({
      screenChannel: `structural-rapid-reimburse`,
      externalClaimId: sessionStorage.getItem('extClaimId')!,
      externalClientId: sessionStorage.getItem('extClientId')!,
      roleType: sessionStorage.getItem('roleType')!,
      authentication: 'partial',
      screenId: 'progress-wheel',
    });

    const $loader = window.oneX.$('#uploadProgess');
    window.oneX.ProgressiveLoader.updatePercent($loader, 1);
    this.addAnotherAfterUpload = this.route.snapshot.queryParamMap.get('addAnother') === 'true';
    this.store
      .select(selectUnsubmittedContracts)
      .pipe(
        take(1),
        tap((contracts) => {
          this.contractsToUpload = contracts;
          if (contracts.length > 0) {
            this.logger.send({
              reportingFile: 'upload.component.ts',
              logLevel: 'INFO',
              message: 'Upload started',
              messageId: 'COVA_UPLOAD_STARTED',
              logData: {},
            });
            this.upload.start(
              contracts[0],
              (percentComplete) => {
                this.currentPercent.next(percentComplete);
              },
              (contract: Contract) => {
                const newContract = CONTRACT_COPY(contract);
                newContract.submitted = true;
                newContract.dateOfSubmission = new Date().toLocaleDateString('en-US');
                this.store.dispatch(updateContract({ contract: newContract }));
                if (this.addAnotherAfterUpload) {
                  this.logger.send({
                    reportingFile: 'upload.component.ts',
                    logLevel: 'INFO',
                    message: 'Go to contract',
                    messageId: 'COVA_UPLOAD_ADD_ANOTHER_GO_TO_CONTRACT',
                    logData: {},
                  });
                  this.ngZone.run(() => {
                    this.router.navigate([`${sessionStorage.getItem('baseUrl')}/contract`], {
                      queryParams: { addAnother: null },
                      queryParamsHandling: 'merge',
                    });
                  });
                } else {
                  const sendCompleteMessage = this.upload.sendCompleteMessage();
                  if (sendCompleteMessage) {
                    sendCompleteMessage.subscribe(() => {
                      this.ngZone.run(() => {
                        this.router.navigate([`${sessionStorage.getItem('baseUrl')}/confirmation`], {
                          queryParams: { addAnother: null },
                          queryParamsHandling: 'merge',
                        });
                      });
                    });
                  } else {
                    this.logger.send({
                      reportingFile: 'upload.component.ts',
                      logLevel: 'INFO',
                      message: 'Go to confirmation',
                      messageId: 'COVA_UPLOAD_NO_ADD_ANOTHER_GO_TO_CONFIRMATION',
                      logData: {},
                    });
                    this.ngZone.run(() => {
                      this.router.navigate([`${sessionStorage.getItem('baseUrl')}/confirmation`], {
                        queryParams: { addAnother: null },
                        queryParamsHandling: 'merge',
                      });
                    });
                  }
                }
              },
              (error: any) => {
                const sendErrorMessage = this.upload.sendErrorMessage();
                if (sendErrorMessage) {
                  sendErrorMessage.subscribe(() => {
                    this.logger.send({
                      reportingFile: 'upload.component.ts',
                      logLevel: 'ERROR',
                      message: 'Unresolvable error message sent',
                      messageId: 'COVA_UNRESOLVABLE_ERROR_MESSAGE_SENT',
                      logData: {},
                    });
                    this.ngZone.run(() => {
                      this.router.navigate(['/error-unresolvable'], {
                        queryParams: { addAnother: null },
                        queryParamsHandling: 'merge',
                      });
                    });
                  });
                } else {
                  this.logger.send({
                    reportingFile: 'upload.component.ts',
                    logLevel: 'ERROR',
                    message: 'Unresolvable error',
                    messageId: 'COVA_UNRESOLVABLE_ERROR',
                    logData: {},
                  });
                  this.ngZone.run(() => {
                    this.router.navigate(['/error-unresolvable'], {
                      queryParams: { addAnother: null },
                      queryParamsHandling: 'merge',
                    });
                  });
                }
              }
            );
          } else if (this.addAnotherAfterUpload) {
            this.logger.send({
              reportingFile: 'upload.component.ts',
              logLevel: 'INFO',
              message: 'Go to contract',
              messageId: 'COVA_NO_UPLOAD_ADD_ANOTHER_GO_TO_CONTRACT',
              logData: {},
            });
            this.ngZone.run(() => {
              this.router.navigate([`${sessionStorage.getItem('baseUrl')}/contract`], {
                queryParams: { addAnother: null },
                queryParamsHandling: 'merge',
              });
            });
          } else {
            this.logger.send({
              reportingFile: 'upload.component.ts',
              logLevel: 'INFO',
              message: 'Go to confirmation',
              messageId: 'COVA_NO_UPLOAD_NO_ADD_ANOTHER_GO_TO_CONFIRMATION',
              logData: {},
            });
            this.ngZone.run(() => {
              this.router.navigate([`${sessionStorage.getItem('baseUrl')}/confirmation`], {
                queryParams: { addAnother: null },
                queryParamsHandling: 'merge',
              });
            });
          }
        })
      )
      .subscribe();
    this.currentPercent.pipe(takeUntil(this.destroyed$)).subscribe((nextPercentage) => {
      window.oneX.ProgressiveLoader.updatePercent($loader, nextPercentage);
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
