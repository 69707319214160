<div class="uploadPage" id="uploadPage" data-testid="uploadPage" ref="parentComp">
  <div class="-oneX-container">
    <div class="-oneX-row -oneX-justify-content-center">
      <div
        class="-oneX-col-xs-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-8 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0"
      >
        <h2 id="uploadingHeaderText" data-testid="uploadingHeaderText" class="upload-header">
          Please do not refresh your screen or you may have to start again!
        </h2>
      </div>
    </div>

    <div class="-oneX-row -oneX-justify-content-center">
      <div
        class="-oneX-col-xs-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-8 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0"
      >
        <div
          id="uploadingPatienceDisclaimerText"
          data-testid="uploadingPatienceDisclaimerText"
          class="-oneX-body--intro-md upload-patience-disclaimer"
        >
          It can take a few minutes to send your contract.
        </div>
      </div>
    </div>
    <div class="-oneX-row -oneX-justify-content-center">
      <div
        class="-oneX-col-xs-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-8 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0"
      >
        <div id="uploadProgess" class="-oneX-progressive-loader-container" data-percent="0">
          <div class="-oneX-progressive-loader-circle">
            <div class="-oneX-progressive-loader-inner">
              <div class="-oneX-progressive-loader-inner-percentage"></div>
              <div class="-oneX-progressive-loader-inner-text">Uploading contract details</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="-oneX-row -oneX-justify-content-center">
      <div
        class="-oneX-col-xs-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-8 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0"
      >
        <div
          id="uploadingPatienceThanksText"
          data-testid="uploadingPatienceThanksText"
          class="-oneX-body--intro-md upload-patience-thanks"
        >
          Thank you for your patience.
        </div>
      </div>
    </div>
  </div>
</div>
