import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class CorrelationIdService {
  private correlationId: string;
  constructor() {
    this.correlationId = uuidv4();
  }

  get() {
    return this.correlationId;
  }
}
