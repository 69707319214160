import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { LoggingService } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';
import { AnalyticsService } from '../../shared/analytics/analytics.service';

@Component({
  selector: 'app-invalid-request-error',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './invalid-request-error.component.html',
  styleUrl: './invalid-request-error.component.css',
})
export class InvalidRequestErrorComponent implements OnInit {
  screenId = 'error-invalid-request';
  constructor(private logger: LoggingService, private analytics: AnalyticsService) {}

  ngOnInit() {
    this.logger.send({
      reportingFile: 'invalid-request-error.component.ts',
      logLevel: 'ERROR',
      message: 'Invalid request error',
      messageId: 'COVA_INVALID_REQUEST_ERROR_PAGE',
      logData: {},
    });
    this.analytics.setAnalyticsDataAngular({
      screenChannel: `structural-rapid-reimburse`,
      externalClaimId: sessionStorage.getItem('extClaimId')!,
      externalClientId: sessionStorage.getItem('extClientId')!,
      roleType: sessionStorage.getItem('roleType')!,
      authentication: 'partial',
      screenId: this.screenId,
    });
  }
}
