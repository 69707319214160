import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoggingService } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';
import { catchError, tap, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthJwtTokenResponse } from '../shared/models/auth.model';
import { CorrelationIdService } from './correlation-id.service';
import { UploadService } from './upload.service';

@Injectable()
export class AuthService {
  private readonly AUTH_REFRESH_INTERVAL = 1000 * 60 * 14 + 500;
  private sessionActive = false;
  sessionInterval: NodeJS.Timer | undefined;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private correlationId: CorrelationIdService,
    private uploadService: UploadService,
    private logger: LoggingService
  ) {
    this.route.queryParamMap.subscribe((map) => {
      if (map.has('sessionTimeout')) {
        this.expire();
      }
    });
  }

  expire() {
    this.logger.send({
      reportingFile: 'auth.service.ts',
      logLevel: 'INFO',
      message: 'Session expired',
      messageId: 'COVA_AUTH_EXIPIRING_SESSION',
      logData: {},
    });
    this.sessionActive = false;
    if (this.sessionInterval) {
      clearInterval(this.sessionInterval);
      this.sessionInterval = undefined;
    }

    const hasSub = this.uploadService.sendCompleteMessage();
    if (hasSub) {
      hasSub.subscribe(() => {
        this.deleteJwtTokens();
      });
    } else {
      this.deleteJwtTokens();
    }
  }

  startAuth(extClaimId: string, extClientId: string) {
    if (this.sessionActive) {
      return true;
    }

    this.logger.send({
      reportingFile: 'auth.service.ts',
      logLevel: 'INFO',
      message: 'Start auth',
      messageId: 'COVA_AUTH_START',
      logData: {},
    });
    this.sessionActive = true;
    const ret = new Promise<boolean>((resolve, reject) => {
      this.retrieveJwtToken(extClaimId, extClientId).subscribe({
        next: () => {
          this.uploadService
            .getUploadId(false, (err: Error) => {
              this.logger.send({
                reportingFile: 'auth.service.ts',
                logLevel: 'ERROR',
                message: 'Error retrieving uploadId in auth',
                messageId: 'COVA_AUTH_ERROR_UNRESOLVABLE',
                logData: {},
              });
              this.router.navigate(['/error-unresolvable'], {
                queryParams: { addAnother: null },
                queryParamsHandling: 'merge',
              });
            })
            ?.subscribe();
          this.sessionInterval = setInterval(() => {
            this.retrieveJwtToken(extClaimId, extClientId).subscribe({
              error: () => {
                this.logger.send({
                  reportingFile: 'auth.service.ts',
                  logLevel: 'ERROR',
                  message: 'Error retrieving token for refresh',
                  messageId: 'COVA_AUTH_ERROR_UNRESOLVABLE',
                  logData: {},
                });
                clearInterval(this.sessionInterval);
                this.sessionInterval = undefined;
                this.sessionActive = false;
                this.router.navigate(['error-unresolvable'], { queryParamsHandling: 'merge' });
              },
            });
            this.uploadService.getUploadId(true)?.subscribe();
          }, this.AUTH_REFRESH_INTERVAL); // 14.5 minutes
          resolve(true);
        },
        error: () => {
          this.sessionActive = false;
          this.logger.send({
            reportingFile: 'auth.service.ts',
            logLevel: 'ERROR',
            message: 'Error retrieving token init',
            messageId: 'COVA_AUTH_ERROR_UNRESOLVABLE_TWO',
            logData: {},
          });
          reject('Error retrieving token init');
          this.router.navigate(['error-unresolvable'], { queryParamsHandling: 'merge' });
        },
      });
    });
    return ret;
  }

  canActivateChild(extClaimId: string, extClientId: string) {
    if (this.sessionActive) {
      return true;
    }
    if (!this.isValidExtClaimId(extClaimId) || !this.isValidExtClientId(extClientId)) {
      this.logger.send({
        reportingFile: 'auth.service.ts',
        logLevel: 'ERROR',
        message: `Malformed request - extClaimId: ${extClaimId} | extClientId: ${extClientId}`,
        messageId: 'COVA_AUTH_MALFORMED_REQUEST_ERROR',
        logData: {},
      });
      this.router.navigate(['error-malformed-request'], { queryParamsHandling: 'merge' });
      return false;
    }
    return this.startAuth(extClaimId, extClientId);
  }

  isValidExtClaimId(extClaimId: string) {
    let isValid = false;
    const regExClaimId = new RegExp('^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$');
    isValid = regExClaimId.test(extClaimId);
    return isValid;
  }

  isValidExtClientId(extClientId: string) {
    let isValid = false;
    const regExClientId = new RegExp('^[a-zA-Z0-9]{20}$');
    isValid = regExClientId.test(extClientId);
    return isValid;
  }

  retrieveJwtToken(extClaimId: string, extClientId: string) {
    this.logger.send({
      reportingFile: 'auth.service.ts',
      logLevel: 'INFO',
      message: 'Start retrieve jwt token',
      messageId: 'COVA_AUTH_RETRIEVE_JWT_TOKEN_START',
      logData: {},
    });
    const correlationId = this.correlationId.get();
    const url = `${environment.api_auth}/oauth2/token`;
    const authId = 'sf-authentication-api-consumer-rapid-reimbursement';
    const httpOptions = {
      headers: new HttpHeaders({
        correlationId,
        'x-api-key': authId,
      }),
    };
    const body = {
      extClaimId,
      extClientId,
    };

    return this.http.post<AuthJwtTokenResponse>(url, body, httpOptions).pipe(
      tap((res) => {
        this.logger.send({
          reportingFile: 'auth.service.ts',
          logLevel: 'INFO',
          message: 'Retrieved jwt token',
          messageId: 'COVA_AUTH_RETRIEVED_JWT_TOKEN',
          logData: {},
        });
        this.cookieService.set('sf-cauth-lite', res.token, undefined, '/', '.statefarm.com', true);
      }),
      catchError((err) => {
        this.logger.send({
          reportingFile: 'auth.service.ts',
          logLevel: 'ERROR',
          message: 'Received error getting auth jwt token',
          messageId: 'COVA_AUTH_ERROR_JWT_TOKEN',
          logData: {},
        });
        return throwError(() => new Error(`Received error getting auth jwt token: ${err.status.toString()}`));
      })
    );
  }

  deleteJwtTokens() {
    this.logger.send({
      reportingFile: 'auth.service.ts',
      logLevel: 'INFO',
      message: 'Deleted jwt token',
      messageId: 'COVA_AUTH_DELETED_JWT_TOKEN',
      logData: {},
    });
    this.cookieService.delete('sf-cauth-lite', '/', '.statefarm.com', true);
  }
}
