<div class="-oneX-container" ref="{parentComp}" id="subnav" data-testid="subnav">
  <div class="-oneX-row">
    <div class="-oneX-col">
      <div>
        <div class="subnavContainer">
          <div
            id="subnavRightBtnContainer"
            data-testid="subnavRightBtnContainer"
            class="subnavRightBtnContainer"
            aria-label="rightBtnText"
            style="display: grid"
            tabIndex="0"
            [attr.data-modal-id]="cancelModalId"
            (click)="openCancelModal($event)"
            (keydown.enter)="openCancelModal($event)"
          >
            <div class="subnavLeftBtnTextContainer" style="display: block">
              <div class="-oneX-icon-container -oneX-icon--interactive">
                <div
                  [attr.data-modal-id]="cancelModalId"
                  class="-oneX-link--block--variant -oneX-link-secondary undefined"
                  id="subnavRightBtnText"
                  data-testid="subnavRightBtnText"
                  aria-hidden
                >
                  Cancel
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  id="reviewPage"
  data-testid="reviewPage"
  class="reviewPage"
  *ngIf="{ contracts: contracts$ | async } as contractsState"
>
  <div class="-oneX-container">
    <div class="-oneX-row -oneX-justify-content-center">
      <div
        class="-oneX-col-12 -oneX-offset-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-8 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0"
      >
        <div class="reviewHeaderContainer" id="reviewHeaderContainer" data-testid="reviewHeaderContainer">
          <h3
            id="reviewHeaderReviewContractsText"
            data-testid="reviewHeaderReviewContractsText"
            class="header-review-contract"
          >
            Review your uploads
          </h3>
        </div>
      </div>
    </div>

    <div class="-oneX-row -oneX-justify-content-center">
      <div
        class="-oneX-col-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-8 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0 header-num-contracts"
      >
        <span
          id="reviewHeaderNumContracts"
          data-testid="reviewHeaderNumContracts"
          class="-oneX-body--intro-md header-contracts"
        >
          {{ contractsState.contracts!.length }}
        </span>
        <span
          id="reviewHeaderContracts"
          data-testid="reviewHeaderContracts"
          class="-oneX-body--intro-md header-contracts"
        >
          contract(s)
        </span>
      </div>
    </div>

    <!-- Uploaded contracts -->
    <div *ngFor="let contract of contractsState.contracts!.slice().reverse(); let i = index">
      <div id="contract" [attr.data-testid]="contract" class="-oneX-row -oneX-justify-content-center">
        <div
          class="-oneX-col-xs-12 -oneX-offset-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-8 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0"
        >
          <!-- Contract card -->
          <div
            id="contract-{{ i }}"
            (click)="navigateToEditOrViewPage(contract)"
            class="-oneX-cards-container contractCard"
            style="cursor: pointer"
          >
            <div class="-oneX-row">
              <!-- Name and status message -->
              <div
                class="-oneX-col-8 -oneX-offset-0 -oneX-col-sm-8 -oneX-offset-sm-0 -oneX-col-md-10 -oneX-offset-md-0 -oneX-col-lg-8 -oneX-offset-lg-0 -oneX-col-xl-8 -oneX-offset-xl-0"
                style="padding-left: 0"
              >
                <div id="contract-{{ i }}-header" class="-oneX-heading--h4 addEllipsis" role="heading" aria-level="4">
                  {{ getHeader(contract) }}
                </div>
                <div id="contract-{{ i }}-subtext" class="-oneX-cards-body addEllipsis">
                  {{ getFirstMessage(contract) }}
                </div>
              </div>

              <!-- Files icon -->
              <div
                class="-oneX-col-2 -oneX-offset-2 -oneX-col-sm-2 -oneX-offset-sm-2 -oneX-col-md-2 -oneX-offset-md-0 -oneX-col-lg-2 -oneX-offset-lg-2 -oneX-col-xl-2 -oneX-offset-xl-2"
              >
                <div
                  tabindex="-1"
                  class="-oneX-row -oneX-align-items-center -oneX-justify-content-center"
                  style="height: 64px; width: 64px; border-radius: 50%; background: #f7f0e4"
                >
                  <div class="contractSubmittedIconParent">
                    <div
                      id="contract-{{ i }}-unsubmitted-icon"
                      class="-oneX-icon -oneX-icon--bg3"
                      data-icon="documents_32"
                      style="height: 32px; width: 32px"
                    ></div>
                    <div
                      *ngIf="contract.submitted"
                      id="contract-{{ i }}-submitted-icon"
                      class="-oneX-icon contractSubmittedSuccessIcon"
                      data-icon="success_fill_24"
                      style="height: 16px; width: 16px; display: inline-block"
                    ></div>
                  </div>
                </div>

                <div class="corners[-text">
                  <span class="-oneX-link--block">{{ getActionMessage(contract) }}</span>
                </div>
              </div>
            </div>

            <div id="contract-{{ i }}-last-message" class="-oneX-row -oneX-justify-content-left">
              {{ getLastMessage(contract) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    <app-contract-upload></app-contract-upload>-->

    <div class="-oneX-row -oneX-justify-content-center">
      <div class="-oneX-row -oneX-justify-content-center">
        <div
          class="-oneX-col-xs-12 -oneX-offset-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-6 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0 -oneX-row -oneX-justify-content-center"
        >
          <button
            id="submitAddBtn"
            data-testid="submitAddBtn"
            type="submit"
            class="-oneX-btn-secondary startButton"
            [attr.data-modal-id]="submitModalId"
            (click)="handleSecondaryButton($event)"
          >
            <!-- Add receipt -->
            {{ getSecondaryButton() }}
          </button>
        </div>
        <div
          class="-oneX-col-xs-12 -oneX-offset-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-6 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0 -oneX-row -oneX-justify-content-center"
        >
          <button
            id="submitBtn"
            data-testid="submitBtn"
            type="submit"
            class="-oneX-btn-primary startButton"
            [attr.data-modal-id]="submitModalId"
            (click)="handlePrimaryButton($event)"
          >
            <!-- Done -->
            {{ getPrimaryButton() }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-cancel-modal [id]="cancelModalId"></app-cancel-modal>
<app-submit-modal [id]="submitModalId" [isAnotherContractAdded]="hasAnotherContract"></app-submit-modal>
