<div id="connectionErrorPage" data-testid="connectionErrorPage">
  <div class="-oneX-container">
    <div classe="-oneX-row">
      <div class="-oneX-col-10 -oneX-offset-1">
        <div class="-oneX-icon-container errorIconContainer">
          <div
            aria-label="Error Icon"
            id="connectionErrorErrorIcon"
            data-testid="connectionErrorErrorIcon"
            class="-oneX-icon errorIcon"
            data-icon="warning_fill_32"
          ></div>
        </div>
      </div>
    </div>
    <div class="-oneX-row errorPageContent">
      <div class="-oneX-col-8 -oneX-offset-2">
        <h2 id="connectionErrorTitleText" data-testid="connectionErrorTitleText">
          We're having trouble with your request
        </h2>
      </div>
    </div>

    <div class="-oneX-row errorPageContent">
      <div class="-oneX-col-8 -oneX-offset-2">
        <div id="connectionErrorBodyText" data-testid="connectionErrorBodyText" class="-oneX-body--primary errorText2">
          Check your internet connection and try again.
        </div>
      </div>
    </div>

    <div class="-oneX-row">
      <div class="-oneX-col-12">
        <button
          id="connectionErrorRetryBtn"
          data-testid="connectionErrorRetryBtn"
          tabIndex="1"
          class="-oneX-btn-primary -oneX-btn-fit-content retryBtn"
        >
          Retry
        </button>
      </div>
    </div>
  </div>
</div>
