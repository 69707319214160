<div [id]="id" class="-oneX-modal__container" style="display: none" data-testid="longContractModal">
  <div
    class="-oneX-modal-content"
    aria-labelledby="longContractModalHeading"
    role="dialog"
    data-testid="longContractModalHeading"
  >
    <div class="-oneX-modal-body">
      <h3 id="longContractModalHeading" class="-oneX-modal-heading" tabindex="-1">
        Take up to 3 photos of your contract or invoice to capture all 4 corners of each page
      </h3>
      <div class="-oneX-row">
        <div class="-oneX-col">
          <img
            src="../../assets/sample_long_receipt.svg"
            alt="Sample Long Contract"
            id="contractUploadLongContractModalExampleImg"
            data-testid="contractUploadLongContractModalExampleImg"
            style="width: 100%"
          />
        </div>
      </div>
      <div class="-oneX-row">
        <div class="-oneX-col">
          <p>
            It’s OK to duplicate sections in your photos. You can add additional contracts, invoices or receipts later
            in the process.
          </p>
        </div>
      </div>
    </div>
    <div class="-oneX-modal-footer">
      <div class="-oneX-row">
        <div class="-oneX-modal-primary__div -oneX-col">
          <button
            id="longContractModalOKBtn"
            (click)="closeLongContractModal()"
            class="-oneX-btn-primary"
            data-testid="longContractModalOKBtn"
          >
            OK, got it
          </button>
        </div>
      </div>
    </div>
    <button
      type="button"
      (click)="closeLongContractModal()"
      class="-oneX-close-X-button"
      aria-label="Close Modal"
      data-testid="longContractModalXCloseBtn"
    >
      <span class="-oneX-icon--close"> </span>
    </button>
  </div>
</div>
