import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { LoggingService } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import { AnalyticsService } from '../../shared/analytics/analytics.service';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css'],
})
export class LandingComponent implements OnInit {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  extClaimId: string;
  extClientId: string;
  roleType: string;
  sessionTimeout: string | null = null;
  screenId: string;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private ngZone: NgZone,
    private logger: LoggingService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.logger.send({
      reportingFile: 'landing.component.ts',
      logLevel: 'INFO',
      message: 'Start app',
      messageId: 'COVA_START_APP',
      logData: {},
    });
    this.screenId = 'home';
    const extClaimId = this.route.snapshot.paramMap.get('extClaimId');
    const extClientId = this.route.snapshot.paramMap.get('extClientId');
    const roleType = this.route.snapshot.paramMap.get('roleType');
    if (!extClaimId || !extClientId || !roleType) {
      this.logger.send({
        reportingFile: 'landing.component.ts',
        logLevel: 'ERROR',
        message: 'Malformed request',
        messageId: 'COVA_LANDING_MALFORMED_REQUEST',
        logData: {},
      });
      this.ngZone.run(() => {
        this.router.navigate(['error-malformed-request'], { queryParamsHandling: 'merge' });
        this.analytics.setAnalyticsDataAngular({
          screenChannel: `structural-rapid-reimburse`,
          externalClaimId: sessionStorage.getItem('extClaimId')!,
          externalClientId: sessionStorage.getItem('extClientId')!,
          roleType: sessionStorage.getItem('roleType')!,
          authentication: 'partial',
          screenId: 'error-malformed-request',
        });
      });
    }
    this.extClaimId = extClaimId!;
    this.extClientId = extClientId!;
    this.roleType = roleType!;
    const baseUrl = `/claim/${extClaimId}/client/${extClientId}/role/${roleType}`;
    sessionStorage.setItem('extClaimId', this.extClaimId);
    sessionStorage.setItem('extClientId', this.extClientId);
    sessionStorage.setItem('roleType', this.roleType);
    sessionStorage.setItem('baseUrl', baseUrl);
    this.route.queryParamMap.pipe(takeUntil(this.destroyed$)).subscribe((next) => {
      if (next.has('sessionTimeout')) {
        setTimeout(() => {
          this.logger.send({
            reportingFile: 'landing.component.ts',
            logLevel: 'INFO',
            message: 'Session timeout',
            messageId: 'COVA_SESSION_TIMEOUT',
            logData: {},
          });
          this.analytics.setAnalyticsDataAngular({
            screenChannel: `structural-rapid-reimburse`,
            externalClaimId: sessionStorage.getItem('extClaimId')!,
            externalClientId: sessionStorage.getItem('extClientId')!,
            roleType: sessionStorage.getItem('roleType')!,
            authentication: 'partial',
            screenId: 'session-expired',
          });
          window.oneX.Modal.showModal(this, 'sessionTimeoutModal');
        }, 1000);
      }
      this.logger.send({
        reportingFile: 'landing.component.ts',
        logLevel: 'INFO',
        message: 'Set analytics data',
        messageId: 'COVA_SET_ANALYTICS_DATA',
        logData: {},
      });
      this.analytics.setAnalyticsDataAngular({
        screenChannel: `structural-rapid-reimburse`,
        externalClaimId: sessionStorage.getItem('extClaimId')!,
        externalClientId: sessionStorage.getItem('extClientId')!,
        roleType: sessionStorage.getItem('roleType')!,
        authentication: 'partial',
        screenId: this.screenId,
      });
    });
  }

  goToEnterContract(): void {
    this.logger.send({
      reportingFile: 'landing.component.ts',
      logLevel: 'INFO',
      message: 'Go to contract',
      messageId: 'COVA_LANDING_GO_TO_CONTRACT',
      logData: {},
    });
    this.ngZone.run(() => {
      this.router.navigate([`${sessionStorage.getItem('baseUrl')}/contract`], { queryParamsHandling: 'merge' });
    });
  }

  hideSessionTimeoutModal() {
    this.logger.send({
      reportingFile: 'landing.component.ts',
      logLevel: 'INFO',
      message: 'Close timeout modal',
      messageId: 'COVA_CLOSE_TIMEOUT_MODAL',
      logData: {},
    });
    this.logger.send({
      reportingFile: 'landing.component.ts',
      logLevel: 'INFO',
      message: 'Go to landing',
      messageId: 'COVA_TIMEOUT_MODAL_GO_TO_LANDING',
      logData: {},
    });
    this.ngZone.run(() => {
      this.router.navigate([sessionStorage.getItem('baseUrl')], {
        queryParams: { sessionTimeout: null },
        queryParamsHandling: 'merge',
      });
    });
    window.oneX.Modal.hideModal();
  }
}
