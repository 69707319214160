import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-subnav',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './subnav.component.html',
  styleUrls: ['./subnav.component.css'],
})
export class SubnavComponent implements AfterViewInit, OnDestroy {
  @Input() leftButtonText?: string;
  @Input() leftButtonIcon?: string;
  @Input() leftButtonClass?: string;
  @Input() isRightButton?: boolean;
  @Output() leftButtonClicked = new EventEmitter<any>();
  @Output() rightButtonClicked = new EventEmitter<boolean>();

  constructor(private element: ElementRef) {}

  ngAfterViewInit() {
    window.oneX.addElement(this.element.nativeElement);
  }

  ngOnDestroy() {
    window.oneX.removeElement(this.element.nativeElement);
  }

  leftButtonWasClicked(event: any) {
    this.leftButtonClicked.emit(event);
  }

  rightButtonWasClicked() {
    this.rightButtonClicked.emit(true);
  }
}
