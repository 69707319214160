<div class="submitPage" id="submitPage" data-testid="submitPage" ref="parentComp">
  <div class="-oneX-container">
    <div class="-oneX-row -oneX-justify-content-center">
      <div
        class="-oneX-row -oneX-justify-content-center -oneX-col-xs-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-8 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0"
      >
        <div class="submitSuccessIconContainer">
          <img
            src="assets/icons/success.svg"
            alt="Success Icon"
            id="submitSuccessHeaderIcon"
            data-testid="submitSuccessHeaderIcon"
            class="submitSuccessIcon"
          />
        </div>
      </div>
    </div>

    <div class="-oneX-row -oneX-justify-content-center">
      <div
        class="-oneX-col-xs-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-8 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0"
      >
        <h1 id="submitRequestSentText" data-testid="submitRequestSentText" class="submitRequestSentText">
          Request sent
        </h1>
      </div>
    </div>

    <div class="-oneX-row -oneX-justify-content-center">
      <div
        class="-oneX-col-xs-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-8 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0"
      >
        <div
          id="submitReviewStatement"
          data-testid="submitReviewStatement"
          class="-oneX-body--intro-md text-content"
          style="text-align: center"
        >
          We&apos;ll review your request for replacement cost benefits and determine amounts payable.
        </div>
      </div>
    </div>

    <div class="-oneX-row -oneX-justify-content-center">
      <div
        class="-oneX-col-xs-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-8 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0"
      >
        <div class="bullet-list">
          <ul>
            <li id="landingText" data-testid="landingText" class="-oneX-body--primary">
              <a
                >Add more items by sending another request using the email or
                <a
                  class="-oneX-link--inline"
                  id="submissionTextLink"
                  tabindex="0"
                  (click)="goToLanding()"
                  (keydown.enter)="goToLanding()"
                  >text link</a
                >
                we sent, or call us at
                <a class="-oneX-link--inline" id="callUsPhoneNumber" href="tel:800-732-5246">800-732-5246</a>.
              </a>
            </li>
            <li id="submitDelayDisclaimer" data-testid="submitDelayDisclaimer" class="-oneX-body--primary">
              <a>You may not see your uploaded files for up to 30 minutes.</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="-oneX-row -oneX-justify-content-center">
      <div
        class="-oneX-col-xs-12 -oneX-offset-xs-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-8 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0 buttonContainer"
      >
        <button
          (click)="goToClaimsHub()"
          id="submitClaimOverviewLink"
          data-testid="submitClaimOverviewLink"
          type="submit"
          class="-oneX-btn-primary -oneX-btn-fit-content"
        >
          Go to your Claims Hub
        </button>
      </div>
    </div>
  </div>
</div>
