import { HttpClientModule } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { contractsReducer } from '../ngrx/contracts.reducer';
import { AuthService } from '../services/auth.service';
import { CorrelationIdService } from '../services/correlation-id.service';
import { appRoutes } from './app.routes';
import { UploadService } from '../services/upload.service';
import { LoggingService } from '../services/logging.service';

export const appConfig: ApplicationConfig = {
  providers: [
    provideStoreDevtools({ logOnly: !isDevMode() }),
    provideEffects(),
    provideStore(),
    provideState({ name: 'contracts', reducer: contractsReducer }),
    provideRouter(appRoutes),
    AuthService,
    LoggingService,
    UploadService,
    importProvidersFrom(HttpClientModule),
    CorrelationIdService,
  ],
};
