import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'rapid-reimbursement-ui-cov-a/src/services/auth.service';

export const canActivateSubmission: CanActivateChildFn = (
  childRoute: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const response = inject(AuthService).canActivateChild(
    childRoute.params['extClaimId'],
    childRoute.params['extClientId']
  );

  return response;
};
