<div
  [id]="id"
  data-testid="cancelModal"
  class="-oneX-modal__container"
  data-disabled-scrim="true"
  style="display: none"
>
  <div class="-oneX-modal-content" aria-labelledby="cancelModalFirstMessage" role="dialog">
    <div
      id="cancelModalFirstMessage"
      data-testid="cancelModalFirstMessage"
      class="-oneX-modal-heading"
      aria-level="2"
      role="heading"
      tabIndex="-1"
      style="text-align: center"
    >
      Are you sure you want to cancel?
    </div>
    <div
      id="cancelModalSecondMessage"
      data-testid="cancelModalSecondMessage"
      class="-oneX-modal-body"
      style="text-align: center"
    >
      No documents will be saved.
    </div>

    <div class="-oneX-modal-footer">
      <div class="-oneX-row">
        <div class="-oneX-modal-primary__div -oneX-col">
          <button
            (click)="goToLanding()"
            id="confirmCancelModalDeleteBtn"
            data-testid="cancelModalDeleteBtn"
            class="-oneX-btn-primary"
            ref="deleteAllConfirmBtnRef"
          >
            Yes, cancel
          </button>
        </div>
        <div class="-oneX-modal-secondary__div -oneX-col">
          <button
            (click)="closeCancelModal()"
            id="confirmCancelModalNoGoBackBtn"
            data-testid="cancelModalCancelBtn"
            class="-oneX-btn-secondary"
            ref="deleteAllCancelBtnRef"
          >
            No, go back
          </button>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="-oneX-close-X-button"
      aria-label="Close Modal"
      onKeyDown="closeDeleteModal()"
      onClick="closeDeleteModal()"
      id="cancelModalCloseBtn"
      data-testid="cancelModalCloseBtn"
      ref="cancelModalCloseBtnRef"
    >
      <span class="-oneX-icon--close"> </span>
    </button>
  </div>
</div>
