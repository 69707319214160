<div id="malformedRequestErrorPage" data-testid="malformedRequestErrorPage" class="">
  <div class="-oneX-container">
    <div class="-oneX-row">
      <div class="-oneX-col-10 -oneX-offset-1">
        <div class="-oneX-icon-container errorIconContainer">
          <div
            aria-label="Error Icon"
            id="malformedRequestErrorErrorIcon"
            data-testid="malformedRequestErrorErrorIcon"
            class="-oneX-icon -oneX-icon -oneX-icon--variant1 errorIcon"
            data-icon="warning_fill_32"
          ></div>
        </div>
      </div>
    </div>
    <div class="-oneX-row errorPageContent">
      <div class="-oneX-col-8 -oneX-offset-2">
        <h2 id="malformedRequestErrorTitleText" data-testid="malformedRequestErrorTitleText" class="">
          We're having trouble with your request
        </h2>
      </div>
    </div>

    <div class="-oneX-row errorPageContent">
      <div class="-oneX-col-12">
        <div id="malformedRequestErrorBodyText" data-testid="malformedRequestErrorBodyText" class="-oneX-body--primary">
          You can try using the link we sent you again or call us at
          <a
            id="malformedRequestErrorPhoneLink"
            data-testid="malformedRequestErrorPhoneLink"
            class="-oneX-link--block"
            href="tel:800-732-5246"
          >
            800-732-5246 </a
          >for help.
        </div>
      </div>
    </div>
  </div>
</div>
