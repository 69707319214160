<div [id]="id" data-testid="submitModal" class="-oneX-modal__container" style="display: none">
  <div class="-oneX-modal-content" aria-labelledby="submitModalHeading" role="dialog">
    <div class="-oneX-modal-body">
      <h2 id="submitModalHeading" class="-oneX-modal-heading" tabindex="-1">
        Are you sure you want to submit this documentation?
      </h2>
      <div class="-oneX-row">
        <div class="-oneX-col">
          <p>You will not be able to make any changes once it is submitted.</p>
        </div>
      </div>
    </div>
    <div class="-oneX-modal-footer">
      <div class="-oneX-row">
        <div class="-oneX-modal-primary__div -oneX-col">
          <button (click)="submitBtnWasClicked()" class="-oneX-btn-primary" id="submitContractModalYesSubmitBtn">
            Yes, submit
          </button>
        </div>
        <div class="-oneX-modal-secondary__div -oneX-col">
          <button (click)="closeSubmitModal()" class="-oneX-btn-secondary" id="submitContractModalNoGoBackBtn">
            No, go back
          </button>
        </div>
      </div>
    </div>
    <button type="button" class="-oneX-close-X-button" aria-label="Close Modal" data-testid="submitModalXCloseBtn">
      <span class="-oneX-icon--close"> </span>
    </button>
  </div>
</div>
