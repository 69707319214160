import { createReducer, on } from '@ngrx/store';
import * as ACTIONS from './contracts.action';
import { ContractsState, initialContractsState } from './contracts.state';

export const contractsReducer = createReducer(
  initialContractsState,
  on(ACTIONS.addContract, (state: ContractsState, action) => {
    return {
      ...state,
      contracts: state.contracts.concat(action.contract),
    };
  }),
  on(ACTIONS.updateContract, (state: ContractsState, action) => {
    const updatedContract = action.contract;
    const contract = state.contracts.find((r) => r.id === updatedContract.id);
    if (contract) {
      const newState: ContractsState = {
        contracts: state.contracts.map((r) => {
          if (r.id === contract?.id) {
            return updatedContract;
          } else {
            return r;
          }
        }),
      };
      return {
        ...state,
        contracts: newState.contracts,
      };
    }
    return {
      ...state,
      contracts: state.contracts,
    };
  }),
  on(ACTIONS.deleteContract, (state: ContractsState, action) => {
    return {
      ...state,
      contracts: state.contracts.filter((contract) => contract.id !== action.contractId),
    };
  }),
  on(ACTIONS.deleteAllContracts, (state, action) => {
    return initialContractsState;
  })
);
