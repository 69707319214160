<div class="appDiv">
  <div>
    <app-header></app-header>
  </div>

  <div>
    <router-outlet></router-outlet>
  </div>

  <div>
    <app-footer></app-footer>
  </div>
</div>
<app-session-timeout-modal></app-session-timeout-modal>
