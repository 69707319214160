import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectUnsubmittedContract } from 'rapid-reimbursement-ui-cov-a/src/ngrx/contracts.selector';
import { Contract } from 'rapid-reimbursement-ui-cov-a/src/shared/models/contract.model';
import { Observable } from 'rxjs';
import { AppState } from '../../app/app.state';
import { AnalyticsService } from '../../shared/analytics/analytics.service';

@Component({
  selector: 'app-submit-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './submit-modal.component.html',
  styleUrls: ['./submit-modal.component.css'],
})
export class SubmitModalComponent implements AfterViewInit, OnDestroy, OnInit {
  unsubmittedContract$: Observable<Contract | undefined>;
  @Input() isAnotherContractAdded: boolean;
  @Input() id: string;

  constructor(
    public element: ElementRef,
    public store: Store<AppState>,
    public router: Router,
    private ngZone: NgZone,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.unsubmittedContract$ = this.store.select(selectUnsubmittedContract);
  }

  ngAfterViewInit() {
    window.oneX.addElement(this.element.nativeElement);
  }

  ngOnDestroy() {
    window.oneX.removeElement(this.element.nativeElement);
  }

  closeSubmitModal() {
    window.oneX.Modal.hideModal();
    this.analytics.setAnalyticsDataAngular({
      screenChannel: `structural-rapid-reimburse`,
      externalClaimId: sessionStorage.getItem('extClaimId')!,
      externalClientId: sessionStorage.getItem('extClientId')!,
      roleType: sessionStorage.getItem('roleType')!,
      authentication: 'partial',
      screenId: 'contractor-summary',
    });
  }

  submitBtnWasClicked() {
    this.closeSubmitModal();
    this.ngZone.run(() => {
      this.router.navigate([`${sessionStorage.getItem('baseUrl')}/upload`], {
        queryParams: { addAnother: this.isAnotherContractAdded },
        queryParamsHandling: 'merge',
      });
    });
  }
}
