import { Injectable } from '@angular/core';

export interface AnalyticsData {
  [key: string]: string | undefined;
}

@Injectable({
  providedIn: 'root', // or specify the module where the service should be provided
})
export class AnalyticsService {
  constructor() {}

  createAnalyticsEvent() {
    const _ev = new CustomEvent('screenChange', {
      bubbles: true,
      cancelable: false,
    });
    window.setTimeout(() => {
      window.dispatchEvent(_ev);
    }, 250);
  }

  setAnalyticsDataAngular(data: AnalyticsData) {
    if (!window.dataLayer) {
      window.dataLayer = {};
    }
    for (const _name in data) {
      const value = data[_name];
      if (value) {
        window.dataLayer[_name] = value;
      }
    }
    this.createAnalyticsEvent();
  }
}
