import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'rapid-reimbursement-ui-cov-a/src/app/app.state';
import { CancelModalComponent } from 'rapid-reimbursement-ui-cov-a/src/modals/cancel-modal/cancel-modal.component';
import { selectContracts, selectUnsubmittedContract } from 'rapid-reimbursement-ui-cov-a/src/ngrx/contracts.selector';
import { LoggingService } from 'rapid-reimbursement-ui-cov-a/src/services/logging.service';
import { ContractUploadComponent } from 'rapid-reimbursement-ui-cov-a/src/shared/contract-upload/contract-upload.component';
import { Contract } from 'rapid-reimbursement-ui-cov-a/src/shared/models/contract.model';
import { SubnavComponent } from 'rapid-reimbursement-ui-cov-a/src/shared/subnav/subnav.component';
import { Observable, ReplaySubject, takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { SubmitModalComponent } from '../../modals/submit-modal/submit-modal.component';
import { AnalyticsService } from '../../shared/analytics/analytics.service';

@Component({
  selector: 'app-review',
  standalone: true,
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css'],
  imports: [CommonModule, CancelModalComponent, ContractUploadComponent, SubnavComponent, SubmitModalComponent],
})
export class ReviewComponent implements AfterViewInit, OnDestroy, OnInit {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  authUrl: string;
  contractId: string;
  contracts$: Observable<Contract[]>;
  hasAnotherContract: boolean = false;
  submitModalId = `submitModal_${uuidv4()}`;
  cancelModalId = `cancelModal_${uuidv4()}`;
  hasUnsubmittedContract = false;

  @Input() isRightButton?: boolean;
  @Output() rightButtonClicked = new EventEmitter<any>();

  unsubmittedContract$: Observable<Contract | undefined>;
  contracts: Contract[] = [];
  screenId: string;
  constructor(
    private el: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private ngZone: NgZone,
    private logger: LoggingService,
    private analytics: AnalyticsService
  ) {
    this.contracts$ = this.store.select(selectContracts);
    this.contracts$.pipe(takeUntil(this.destroyed$)).subscribe((contracts) => {
      this.contracts = contracts;
    });
  }

  ngOnInit() {
    this.logger.send({
      reportingFile: 'review.component.ts',
      logLevel: 'INFO',
      message: 'Review page',
      messageId: 'COVA_REVIEW_PAGE',
      logData: {},
    });
    this.logger.send({
      reportingFile: 'review.component.ts',
      logLevel: 'INFO',
      message: 'Contracts loaded',
      messageId: 'COVA_CONTRACTS_LOADED',
      logData: {},
    });
    this.screenId = 'contractor-summary';
    this.unsubmittedContract$ = this.store.select(selectUnsubmittedContract);
    this.unsubmittedContract$.subscribe((contracts) => {
      this.hasUnsubmittedContract = !!contracts;
    });
    const extClaimId = this.route.snapshot.params['extClaimId'];
    const extClientId = this.route.snapshot.params['extClientId'];
    const roleType = this.route.snapshot.params['roleType'];
    this.authUrl = `claim/${extClaimId}/client/${extClientId}/role/${roleType}`;

    this.analytics.setAnalyticsDataAngular({
      screenChannel: `structural-rapid-reimburse`,
      externalClaimId: sessionStorage.getItem('extClaimId')!,
      externalClientId: sessionStorage.getItem('extClientId')!,
      roleType: sessionStorage.getItem('roleType')!,
      authentication: 'partial',
      screenId: this.screenId,
    });
  }

  ngAfterViewInit() {
    window.oneX.addElement(this.el.nativeElement);
  }

  ngOnDestroy() {
    window.oneX.removeElement(this.el.nativeElement);
  }

  getHeader(contract: Contract) {
    if (contract.submitted) {
      return 'SUBMITTED CONTRACT';
    } else {
      return contract.nameOfContractor.toUpperCase();
    }
  }
  getFirstMessage(contract: Contract) {
    if (contract.submitted) {
      return contract.nameOfContractor;
    } else {
      return 'Not submitted';
    }
  }

  getActionMessage(contract: Contract) {
    if (contract.submitted) {
      return 'View';
    } else {
      return 'Edit';
    }
  }

  getLastMessage(contract: Contract) {
    if (contract.submitted) {
      return `Submitted on ${contract.dateOfSubmission}`;
    } else {
      return `The contract/receipt can't be changed once it is submitted`;
    }
  }

  navigateToEditOrViewPage(contract: Contract) {
    if (contract.submitted) {
      this.logger.send({
        reportingFile: 'review.component.ts',
        logLevel: 'INFO',
        message: 'Go to view contract',
        messageId: 'COVA_REVIEW_GO_TO_VIEW_CONTRACT',
        logData: {},
      });
      this.ngZone.run(() => {
        this.router.navigate([`${this.authUrl}/contract/${contract.id}/view`], { queryParamsHandling: 'merge' });
      });
    } else {
      this.logger.send({
        reportingFile: 'review.component.ts',
        logLevel: 'INFO',
        message: 'Go to edit contract',
        messageId: 'COVA_REVIEW_GO_TO_EDIT_CONTRACT',
        logData: {},
      });
      this.ngZone.run(() => {
        this.router.navigate([`${this.authUrl}/contract/${contract.id}/edit`], { queryParamsHandling: 'merge' });
      });
    }
  }

  openCancelModal(event: any) {
    this.logger.send({
      reportingFile: 'review.component.ts',
      logLevel: 'INFO',
      message: 'Cancel modal opened',
      messageId: 'COVA_CANCEL_MODAL_OPENED',
      logData: {},
    });
    this.rightButtonClicked.emit(event);
    const $returnFocus = window.oneX.$(event.target);
    const which = $returnFocus.attr('data-modal-id');
    window.oneX.Modal.showModal($returnFocus, which);
  }

  openSubmitModal(event: any, addAnotherContract: boolean) {
    this.logger.send({
      reportingFile: 'review.component.ts',
      logLevel: 'INFO',
      message: 'Submit modal opened',
      messageId: 'COVA_SUBMIT_MODAL_OPENED',
      logData: {},
    });
    this.hasAnotherContract = addAnotherContract;
    const $returnFocus = window.oneX.$(event.target);
    const which = $returnFocus.attr('data-modal-id');
    window.oneX.Modal.showModal($returnFocus, which);

    this.screenId = 'submit-contract-modal';
    this.analytics.setAnalyticsDataAngular({
      screenChannel: `structural-rapid-reimburse`,
      externalClaimId: sessionStorage.getItem('extClaimId')!,
      externalClientId: sessionStorage.getItem('extClientId')!,
      roleType: sessionStorage.getItem('roleType')!,
      authentication: 'partial',
      screenId: this.screenId,
    });
  }

  getPrimaryButton() {
    if (this.hasUnsubmittedContract) {
      return 'Submit';
    } else {
      return 'Done';
    }
  }

  getSecondaryButton() {
    if (this.hasUnsubmittedContract) {
      return 'Submit & add another';
    } else {
      return 'Add contract';
    }
  }

  handlePrimaryButton(event: any) {
    if (this.hasUnsubmittedContract) {
      this.openSubmitModal(event, false);
    } else {
      this.logger.send({
        reportingFile: 'review.component.ts',
        logLevel: 'INFO',
        message: 'Go to confirmation',
        messageId: 'COVA_REVIEW_GO_TO_CONFIRMATION',
        logData: {},
      });
      this.ngZone.run(() => {
        this.router.navigate([`${sessionStorage.getItem('baseUrl')}/confirmation`], { queryParamsHandling: 'merge' });
      });
    }
  }

  handleSecondaryButton(event: any) {
    if (this.hasUnsubmittedContract) {
      this.openSubmitModal(event, true);
    } else {
      this.logger.send({
        reportingFile: 'review.component.ts',
        logLevel: 'INFO',
        message: 'Go to contract',
        messageId: 'COVA_REVIEW_GO_TO_CONTRACT',
        logData: {},
      });
      this.ngZone.run(() => {
        this.router.navigate([`${sessionStorage.getItem('baseUrl')}/contract`], { queryParamsHandling: 'merge' });
      });
    }
  }
}
