import { Contract } from '../shared/models/contract.model';

export const CONTRACTS_FEATURE_KEY = 'contracts';

export interface ContractsState {
  contracts: Contract[];
}

export const initialContractsState: ContractsState = {
  contracts: [],
};
