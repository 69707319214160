<div [id]="id" data-testid="deleteContractModalId" class="-oneX-modal__container" style="display: none">
  <div class="-oneX-modal-content" aria-labelledby="deleteContractModalHeading" role="dialog">
    <div class="-oneX-modal-body">
      <h2 id="deleteContractModalHeading" class="-oneX-modal-heading" tabindex="-1">
        Are you sure you want to delete your contract?
      </h2>
      <div class="-oneX-row">
        <div class="-oneX-col">
          <p id="deleteContractModalSubtext">All information (including documentation) will be deleted.</p>
        </div>
      </div>
    </div>
    <div class="-oneX-modal-footer">
      <div class="-oneX-row">
        <div class="-oneX-modal-primary__div -oneX-col">
          <button
            (click)="deleteContract()"
            id="deleteContractModalYesDeleteBtn"
            class="-oneX-btn-primary"
            data-testid="deleteContractModalYesDeleteBtn"
          >
            Yes, delete
          </button>
        </div>
        <div class="-oneX-modal-secondary__div -oneX-col">
          <button (click)="closeDeleteContractModal()" class="-oneX-btn-secondary" id="deleteContractModalNoGoBackBtn">
            No, go back
          </button>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="-oneX-close-X-button"
      aria-label="Close Modal"
      data-testid="deleteContractModalXCloseBtn"
    >
      <span class="-oneX-icon--close"> </span>
    </button>
  </div>
</div>
