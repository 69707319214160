<div id="unsubmittedContractCard" data-testid="unsubmittedContractCard" class="-oneX-row -oneX-justify-content-center">
  <div
    class="-oneX-col-xs-12 -oneX-offset-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-8 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0"
  >
    <!-- Contract card -->
    <div class="-oneX-cards-container contractCard">
      <div class="-oneX-row">
        <!-- Name and status message -->
        <div
          class="-oneX-col-8 -oneX-offset-0 -oneX-col-sm-8 -oneX-offset-sm-0 -oneX-col-md-10 -oneX-offset-md-0 -oneX-col-lg-8 -oneX-offset-lg-0 -oneX-col-xl-8 -oneX-offset-xl-0"
        >
          <div class="-oneX-heading--h4" role="heading" aria-level="4">
            {{ getName() }}
          </div>
          <div class="-oneX-cards-body">
            {{ getContractStatus() }}
          </div>
        </div>

        <!-- Files icon -->
        <div
          class="-oneX-col-2 -oneX-offset-2 -oneX-col-sm-2 -oneX-offset-sm-2 -oneX-col-md-2 -oneX-offset-md-0 -oneX-col-lg-2 -oneX-offset-lg-2 -oneX-col-xl-2 -oneX-offset-xl-2"
        >
          <div
            tabindex="-1"
            class="-oneX-row -oneX-align-items-center -oneX-justify-content-center"
            style="height: 64px; width: 64px; border-radius: 50%; background: #f7f0e4"
          >
            <div class="-oneX-icon -oneX-icon--bg3" data-icon="documents_32" style="height: 32px; width: 32px"></div>
          </div>

          <div class="corners[-text">
            <a (click)="navigateToEditPage()" class="-oneX-link--block" onclick="">Edit</a>
          </div>
        </div>
      </div>

      <div class="-oneX-row -oneX-justify-content-center">
        {{ getMessage() }}
      </div>
    </div>
  </div>
</div>

<div id="submittedContractCard" data-testid="submittedContractCard" class="-oneX-row -oneX-justify-content-center">
  <div
    class="-oneX-col-12 -oneX-offset-0 -oneX-col-sm-12 -oneX-offset-sm-0 -oneX-col-md-8 -oneX-offset-md-0 -oneX-col-lg-6 -oneX-offset-lg-0 -oneX-col-xl-6 -oneX-offset-xl-0"
  >
    <!-- Contract card -->
    <div class="-oneX-cards-container contractCard">
      <div class="-oneX-row">
        <!-- Name and status message -->
        <div
          class="-oneX-col-8 -oneX-offset-0 -oneX-col-sm-8 -oneX-offset-sm-0 -oneX-col-md-10 -oneX-offset-md-0 -oneX-col-lg-10 -oneX-offset-lg-0 -oneX-col-xl-8 -oneX-offset-xl-0"
        >
          <div class="-oneX-heading--h4" role="heading" aria-level="4">
            <!-- {{ getName() }} -->
            SUBMITTED CONTRACT
          </div>
          <div class="-oneX-cards-body">
            <!-- {{ getContractStatus() }} -->
            Terry Roofers
          </div>
        </div>

        <!-- Files icon -->
        <div
          class="-oneX-col-2 -oneX-offset-2 -oneX-col-sm-2 -oneX-offset-sm-2 -oneX-col-md-2 -oneX-offset-md-0 -oneX-col-lg-2 -oneX-offset-lg-0 -oneX-col-xl-2 -oneX-offset-xl-2"
        >
          <div
            tabindex="-1"
            class="-oneX-row -oneX-align-items-center -oneX-justify-content-center"
            style="height: 64px; width: 64px; border-radius: 50%; background: #f7f0e4"
          >
            <div class="-oneX-icon -oneX-icon--bg3" data-icon="documents_32" style="height: 32px; width: 32px"></div>
            <div
              class="-oneX-icon"
              data-icon="success_fill_24"
              style="height: 16px; width: 16px; margin-bottom: 50px; margin-left: 50px; position: absolute"
            ></div>
          </div>

          <div class="corners-text">
            <a href="javascript:void(0)" class="-oneX-link--block" onclick="">View</a>
          </div>
        </div>
      </div>

      <div class="-oneX-row -oneX-justify-content-center">
        <!-- {{ getMessage() }} -->
        Submitted on 09/15/2023
      </div>
    </div>
  </div>
</div>
