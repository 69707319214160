import { formatDate } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';

export interface Contract {
  id: string;
  nameOfContractor: string;
  contractorsPhoneNumber: string;
  contractSignedDate: string;
  totalAmountOfContract: number;
  repairsSubstantiallyUnderway: string;
  differenceBetweenEstimateAndContract: string;
  photosOfContract: Array<File>;
  submitted: boolean;
  dateOfSubmission: string;
}

export const CONTRACT_EXAMPLE = (id = uuidv4()) => {
  const r: Contract = {
    id: id,
    nameOfContractor: 'EXMAPLE',
    contractorsPhoneNumber: '123-123-1234',
    contractSignedDate: '01-09-2024',
    totalAmountOfContract: 44.34,
    repairsSubstantiallyUnderway: 'Yes',
    differenceBetweenEstimateAndContract: 'No',
    photosOfContract: [
      new File(['blob:http://localhost:3000/542e688c-abe9-48bd-8df1-5b2443f5dc0d'], 'pic1.png', {
        type: 'image/png',
      }),
    ],
    submitted: false,
    dateOfSubmission: '',
  };
  return r;
};

export const CONTRACT_EXAMPLE_2 = (id = uuidv4()) => {
  const r: Contract = {
    id: id,
    nameOfContractor: 'EXMAPLE',
    contractorsPhoneNumber: '123-123-1234',
    contractSignedDate: '01-09-2024',
    totalAmountOfContract: 44.34,
    repairsSubstantiallyUnderway: 'Yes',
    differenceBetweenEstimateAndContract: 'No',
    photosOfContract: [
      new File(['blob:http://localhost:3000/542e688c-abe9-48bd-8df1-5b2443f5dc0d'], 'pic1.png', {
        type: 'image/png',
      }),
      new File(['blob:http://localhost:3000/542e688c-abe9-48bd-8df1-5b2443f5dc0d'], 'pic2.jpeg', {
        type: 'image/jpeg',
      }),
    ],
    submitted: false,
    dateOfSubmission: '',
  };
  return r;
};

export const CONTRACT_EXAMPLE_3 = (id = uuidv4()) => {
  const r: Contract = {
    id: id,
    nameOfContractor: 'EXMAPLE',
    contractorsPhoneNumber: '123-123-1234',
    contractSignedDate: '01-09-2024',
    totalAmountOfContract: 44.34,
    repairsSubstantiallyUnderway: 'Yes',
    differenceBetweenEstimateAndContract: 'No',
    photosOfContract: [
      new File(['blob:http://localhost:3000/542e688c-abe9-48bd-8df1-5b2443f5dc0d'], 'pic1.png', {
        type: 'image/png',
      }),
      new File(['blob:http://localhost:3000/542e688c-abe9-48bd-8df1-5b2443f5dc0d'], 'pic2.jpeg', {
        type: 'image/jpeg',
      }),
      new File(['blob:http://localhost:3000/542e688c-abe9-48bd-8df1-5b2443f5dc0d'], 'pic3.jpeg', {
        type: 'image/jpeg',
      }),
    ],
    submitted: false,
    dateOfSubmission: '',
  };
  return r;
};

export const CONTRACT_EXAMPLE_SUBMITTED = (id = uuidv4()) => {
  const c = CONTRACT_EXAMPLE(id);
  c.submitted = true;
  c.dateOfSubmission = formatDate(new Date(), 'MM/dd/yyyy', 'en');
  return c;
};

export const CONTRACT_EMPTY = (id = uuidv4()) => {
  const r: Contract = {
    id: id,
    nameOfContractor: '',
    contractorsPhoneNumber: '',
    contractSignedDate: '',
    totalAmountOfContract: NaN,
    repairsSubstantiallyUnderway: '',
    differenceBetweenEstimateAndContract: '',
    photosOfContract: new Array<File>(),
    submitted: false,
    dateOfSubmission: '',
  };
  return r;
};

export const CONTRACT_COPY = (r: Contract) => {
  const nr: Contract = {
    id: r.id,
    nameOfContractor: r.nameOfContractor,
    contractorsPhoneNumber: r.contractorsPhoneNumber,
    contractSignedDate: r.contractSignedDate,
    totalAmountOfContract: r.totalAmountOfContract,
    repairsSubstantiallyUnderway: r.repairsSubstantiallyUnderway,
    differenceBetweenEstimateAndContract: r.differenceBetweenEstimateAndContract,
    photosOfContract: r.photosOfContract,
    submitted: r.submitted,
    dateOfSubmission: r.dateOfSubmission,
  };
  return nr;
};
