<div id="unresolvableErrorPage" data-testid="unresolvableErrorPage" class="">
  <div class="-oneX-container">
    <div class="-oneX-row">
      <div class="-oneX-col-10 -oneX-offset-1">
        <div class="-oneX-icon-container errorIconContainer">
          <div
            aria-label="Error Icon"
            id="unresolvableErrorErrorIcon"
            data-testid="unresolvableErrorErrorIcon"
            class="-oneX-icon -oneX-icon -oneX-icon--variant1 errorIcon"
            data-icon="warning_fill_32"
          ></div>
        </div>
      </div>
    </div>
    <div class="-oneX-row errorPageContent">
      <div class="-oneX-col-8 -oneX-offset-2">
        <h2 id="unresolvableErrorTitleText" data-testid="unresolvableErrorTitleText">There's still a problem</h2>
      </div>
    </div>

    <div class="-oneX-row errorPageContent">
      <div class="-oneX-col-12">
        <div id="unresolvableErrorBodyText" data-testid="unresolvableErrorBodyText" class="-oneX-body--primary">
          Sorry, we can't complete this request right now. You can try again later or call us at
          <a
            id="unresolvableErrorPhoneLink"
            data-testid="unresolvableErrorPhoneLink"
            class="-oneX-link--block"
            href="tel:800-732-5246"
          >
            800-732-5246
          </a>
          for help.
        </div>
      </div>
    </div>
  </div>
</div>
